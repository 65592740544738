export const buildOptions = (data, title, borderColor) => {
  return {
    tooltip: {
      trigger: 'item'
    },
    legend: data.length <= 4 ? {
      bottom: 'bottom',
      left: 'center'
    } : undefined,
    textStyle: {
      fontFamily: 'Nunito'
    },
    series: [
      {
        name: title,
        type: 'pie',
        radius: ['30%', '60%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: borderColor,
          borderWidth: 2
        },
        label: {
          show: false,
        },
        emphasis: {
        },
        labelLine: {
          show: false
        },
        data: data.map((item) => ({ name: item.name, value: Math.round(item.value) })),
      }
    ]
  }
}