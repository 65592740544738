import React from 'react';
import ReactModal from 'react-modal';


const PositionalModal = ({
  positionFrom,
  isOpen,
  close,
  children,
  height = '10em',
  width = '10em'
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => close()}
      style={{
        content: {
          // background: '#f0f0f5',
          top: positionFrom.top,
          left: positionFrom.left,
          width,
          height,
          borderRadius: '22px 10px 10px 10px',
          marginLeft: '.75em',
          // marginTop: '1em',
          background: 'var(--modal-background)',
          padding: '.5em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          border: 'none',
          boxShadow: '4px 4px 8px 0px rgba(34, 60, 80, 0.2)',
          outline: 'none',
          zIndex: 4,
        },
        overlay: {
          zIndex: 3,
          backgroundColor: 'none'
        },
      }}
    >
      {children}
    </ReactModal>
  )
}

export default PositionalModal;
