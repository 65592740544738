import React from 'react';
import { useForm } from 'react-hook-form';
import { createRecord } from '../../transport/api';
import Input from '../../components/input';

import ComponentContainer from '../../components/ComponentContainer/index';
import Button from '../../components/button/index';
import { toast } from 'react-toastify';

const PhysicalBalance = ({ close }) => {
  const { register, handleSubmit } = useForm({ defaultValues: {} });

  const onSubmit = async (data) => {
    return createRecord(`/physical-balances/new`, data)
      .then(() => {
        toast.success('Физический баланс успешно добавлен');
        close();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="physical-balance-form">
        <Input
          label="Название"
          register={() => register('name', { required: true })}
        />
      </form>
      <Button form="physical-balance-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default PhysicalBalance;
