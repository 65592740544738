import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createRecord, requestEntity } from '../../transport/api';
import Input from '../../components/input';

import ComponentContainer from '../../components/ComponentContainer/index';
import Button from '../../components/button/index';
import { toast } from 'react-toastify';

const Loss = ({ supplyId, lossId, forCreate, close, remainingVolume }) => {
  const { register, handleSubmit, getValues } = useForm({
    defaultValues: async () =>
      !forCreate ? requestEntity(`/supplies/${supplyId}/losses`, lossId) : {},
  });

  const [isButtonDisabled, toggleButtonDisabled] = useState(false);

  const onSubmit = async (data) => {
    toggleButtonDisabled(true);
    return createRecord(`/supplies/${supplyId}/losses/new`, data)
      .then(() => {
        toast.success('Списание успешно добавлена');
        close();
      })
      .catch((error) => toast.error(error.message))
      .finally(() => toggleButtonDisabled(false));
  };
  return (
    <ComponentContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="loss-form">
        <Input
          label="Объём"
          type="number"
          options={{ min: 0, max: remainingVolume + getValues('volume') }}
          register={() => register('volume', { required: true, disabled: !forCreate })}
        />
        <Input
          label="Причина"
          register={() => register('cause', { required: true, disabled: !forCreate })}
        />
      </form>
      {forCreate ? (
        <Button form="loss-form" type="submit" disabled={isButtonDisabled}>
          Сохранить
        </Button>
      ) : undefined}
    </ComponentContainer>
  );
};

export default Loss;
