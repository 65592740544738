import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavbarLink.module.css';

const NavbarLink = ({ icon, to, children, selected }) => {
  return (
    <NavLink to={to} className={selected ? styles['container-selected'] : styles.container}>
      {icon ? <img src={icon} alt={`${to}_icon`} className={selected ? styles['icon-selected'] : styles.icon} /> : <div className={styles.icon} />}
      <div>{children}</div>
    </NavLink>
  )
}

export default NavbarLink;
