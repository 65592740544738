import ReactModal from 'react-modal';
import styles from './TriggerModal.module.css';
import React, { useEffect, useState } from 'react';
import { createRecord, requestTable } from '../../transport/api';
import Input from '../input';
import EmojiButton from '../EmojiButton';
import { toast } from 'react-toastify';
import ConfirmationModal from '../ConfirmationModal';
import Switch from '../Switch';


const TriggerModal = ({ isOpen, columns, url, close }) => {
  const [triggersData, setTriggersData] = useState([]);
  const [showConfirmation, toggleShowConfirmation] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState('');

  useEffect(() => {
    requestTable(url, [], ['id'], 1, 300)
      .then((data) => setTriggersData(data.list));
  }, [url]);

  const updateTrigger = (value, index, name) => {
    setTriggersData((prevState) => {
      const data = structuredClone(prevState);
      data[index][name] = value || (
        value === false ? false : null
      );
      return data;
    });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      className={`${styles.container} ${columns.length > 2 ? styles['big-container'] : ''}`}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
      onRequestClose={() => toggleShowConfirmation(true)}
    >
      <div>
        <div className={styles['search-container']}>
          <Input
            className={styles.search}
            value={triggerFilter}
            onChange={(e) => setTriggerFilter(() => e.target.value)}
            placeholder="Поиск 🔎"
          />
        </div>
        <div className={`${styles.table} ${columns.length === 2 ? styles['column-2'] : styles['column-4']}`}>
          {columns.map((column, index) => <div key={`column-${index}`}>{column?.title}</div>)}
          {triggersData.map((trigger, index) => (
            !triggerFilter || trigger[columns[0].key].toLowerCase().includes(triggerFilter.toLowerCase()) ? [
              <div className={styles.cell} key={`name-${index}`}>{trigger[columns[0].key]}</div>,
              columns.length > 2 ? <div className={styles.cell} key={`trigger-${index}`}>
                <Input
                  key="bad"
                  className={styles.input}
                  value={triggersData[index].bad || ''}
                  onChange={(e) => updateTrigger(e.target.value, index, 'bad')}
                  label="Плохо"
                />
                <Input
                  key="dangerous"
                  className={styles.input}
                  value={triggersData[index].dangerous || ''}
                  onChange={(e) => updateTrigger(e.target.value, index, 'dangerous')}
                  label="Опасно"
                />
              </div> : undefined,
              <div className={styles.cell} key={`trigger-wb-${index}`}>
                <Input
                  key="bad"
                  className={styles.input}
                  value={triggersData[index].wb_bad || ''}
                  onChange={(e) => updateTrigger(e.target.value, index, 'wb_bad')}
                  label="Плохо"
                />
                <Input
                  key="dangerous"
                  className={styles.input}
                  value={triggersData[index].wb_dangerous || ''}
                  onChange={(e) => updateTrigger(e.target.value, index, 'wb_dangerous')}
                  label="Опасно"
                />
              </div>,
              columns.length > 2 ? <Switch
                value={`${index}-${triggersData[index].hide_in_turnover}`}
                onChange={(e) => updateTrigger(e.target.value === `${index}-true`, index, 'hide_in_turnover')}
                containerClassName={styles.switch}
                options={[
                  { value: `${index}-true`, label: 'Да' },
                  { value: `${index}-false`, label: 'Нет' },
                ]}
              /> : undefined,
            ].filter((item) => !!item) : []
          ))}
        </div>
        <EmojiButton
          containerClassName={styles['fixed-button-container']}
          className={styles['fixed-button']}
          emoji="✔️"
          onClick={() => createRecord(url, triggersData)
            .then(() => {
              toast.success('Триггеры успешно обновлены');
              close();
            })
            .catch((error) => toast.error(error.message))}
        />
        <ConfirmationModal
          isOpen={showConfirmation}
          title="Вы уверены, что хотите отменить изменения?"
          confirmTitle="Да"
          cancelTitle="Нет"
          onCancel={() => toggleShowConfirmation(() => false)}
          onConfirm={() => {
            toggleShowConfirmation(() => false);
            close();
          }}
        />
      </div>
    </ReactModal>
  )
}

export default TriggerModal;
