import React, { useEffect, useMemo, useState } from 'react';

import styles from './Button.module.css';

const Button = ({ children, onClick, className, disabled, type, theme, form, hidden, title }) => {
  const themes = useMemo(() => ({
    secondary: styles.secondary,
    borderless: styles.borderless,
  }), [])
  const [styleName, setStyleName] = useState('')
  const [isLoading, toggleLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(themes).includes(theme)) {
      setStyleName(() => themes[theme])
    }
  }, [theme, themes])

  return hidden ? null : (
    <button
      title={title}
      onClick={() => {
        if (onClick) {
          toggleLoading(true);
          Promise.resolve(onClick()).finally(() => toggleLoading(false));
        }
      }}
      className={`${styles.button} ${className} ${styleName}`}
      disabled={isLoading || disabled}
      type={type}
      form={form}
    >
      {isLoading ? '⏳' : children}
    </button>
  );
}

export default Button;
