import React from 'react';
import styles from './Switch.module.css';

const Switch = ({
  options,
  value,
  onChange,
  label,
  containerClassName,
  register,
  isFilter = false,
}) => {
  const registerResult = { ...(register && register()) };
  return (
    <div
      className={`${styles.container} ${containerClassName} ${
        isFilter ? styles.filter : ''
      }`}
    >
      {label ? (
        <div key="label" className={styles.label}>
          {label}
        </div>
      ) : undefined}
      <div key="buttons-container" className={styles['buttons-container']}>
        {options.map((option) => (
          <div
            key={`container_${option.label}`}
            className={`${styles.button} ${
              isFilter ? styles['filter-button'] : ''
            } ${registerResult?.disabled ? styles['button-disabled'] : ''}`}
          >
            <input
              {...registerResult}
              id={option.value}
              type="radio"
              value={option.value}
              {...(onChange && {onChange: onChange})}
              {...(value && {checked: option.value === value})}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Switch;
