import { deleteRecord } from '../../transport/api';
import { toast } from 'react-toastify';
import ConfirmationModal from '../ConfirmationModal';
import React from 'react';
import { ResponseError } from '../../transport/Errors';

const DeleteModal = ({ show, title, path, close, callback }) => (
  <ConfirmationModal
    isOpen={show}
    title={title}
    confirmTitle='Да'
    cancelTitle='Нет'
    onCancel={() => close()}
    onConfirm={() => {
      deleteRecord(path)
        .then(() => {
          close();
          toast.info('Запись удалена');
          callback && callback();
        })
        .catch((e) => {
          if (e instanceof ResponseError) {
            toast.error(e.message);
          } else {
            toast.error('При удалении записи произошла непредвиденная ошибка');
          }
        })
    }}
  />
)

export default DeleteModal;
