import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { createRecord, requestEntity, updateRecord } from '../../transport/api';
import ComponentContainer from '../../components/ComponentContainer';
import UncontrolledSelect from '../../components/UncontrolledSelect';
import Input from '../../components/input';
import Button from '../../components/button';
import Switch from '../../components/Switch';
import RecordTable from '../../components/RecordTable';
import AddTitle from '../../components/AddTitle';

import styles from './Balance.module.css';
import EditModal from './../../components/EditModal/index';
import Dividend from './Dividend';
import Operation from '../operations/Operation';

const Investor = ({ investorId, forCreate, close }) => {
  const [newDividend, toggleNewDividend] = useState(undefined);
  const [investorType, setInvestorType] = useState('DEPOSITOR');
  const [status, setStatus] = useState();
  const [newOperation, setNewOperation] = useState();

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: !forCreate
      ? async () =>
          requestEntity(`/investors`, investorId).then((result) => {
            setInvestorType(result.investor_type);
            setStatus(result.status);
            return result;
          })
      : { investor_type: 'DEPOSITOR' },
  });

  useEffect(() => {
    if (!newDividend && !newOperation && investorId) {
      requestEntity(`/investors`, investorId).then((result) => {
        setValue('payment', result.payment);
        setValue('not_entered', result.not_entered);
        setValue('profit_payment', result.profit_payment);
      });
    }
  }, [newDividend, newOperation, investorId, setValue]);

  const onSubmit = async (data) => {
    if (forCreate) {
      const createData = investorType === 'DEPOSITOR'
        ? { ...data, month_pay: undefined }
        : { ...data, goods_id: undefined, percent: undefined };

      return createRecord(`/investors/new`, createData)
        .then(() => {
          toast.success('Инвестор успешно добавлен');
          close();
        })
        .catch((error) => toast.error(error.message));
    }
    return updateRecord(`/investors/${investorId}`, data)
      .then(() => {
        toast.success('Данные инвестора успешно обновлены');
        close();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="investor-form">
        <Input
          label="Имя"
          register={() =>
            register('name', { required: forCreate, disabled: !forCreate })
          }
        />
        {!forCreate ? (
          <Input
            label="Размер инвестиции"
            type="number"
            options={{ min: 0 }}
            register={() =>
              register('investment', {
                disabled: true,
              })
            }
          />
        ) : undefined}
        <Switch
          label="Тип инвестора?"
          options={[
            { value: 'DEPOSITOR', label: 'Вкладчик' },
            { value: 'CREDITOR', label: 'Кредитор' },
          ]}
          register={() =>
            register('investor_type', {
              required: forCreate,
              disabled: !forCreate,
              onChange: (e) => setInvestorType(e.target.value),
            })
          }
        />
        {investorType === 'DEPOSITOR' ? (
          <div>
            <UncontrolledSelect
              control={control}
              name="goods_id"
              label="Проект"
              resourcePath="/goods/value-list"
              disabled={!forCreate}
              optionsColumns={{ value: 'id', label: 'name' }}
            />
            <Input
              label="Процент с его доли"
              type="number"
              options={{ min: 0, max: 100 }}
              register={() =>
                register('percent', {
                  required: investorType === 'DEPOSITOR',
                })
              }
            />
          </div>
        ) : investorType === 'CREDITOR' ? (
          <Input
            label="Ежемесячный платёж"
            type="number"
            options={{ min: 0 }}
            register={() =>
              register('month_pay', {
                required: investorType === 'CREDITOR',
                disabled: status === 'CLOSED',
              })
            }
          />
        ) : undefined}
        {!forCreate ? (
          <UncontrolledSelect
            control={control}
            name="status"
            label="Статус"
            disabled={status === 'CLOSED'}
            resourcePath="/investors/statuses"
            optionsColumns={{ value: 'value', label: 'label' }}
          />
        ) : undefined}
        {!forCreate && investorType === 'DEPOSITOR'  ? (
          <Input
            label="Прибыль, доступная для вывода"
            type="number"
            register={() =>
              register('profit_payment', {
                disabled: true,
              })
            }
          />
        ) : undefined}
        {!forCreate && investorType === 'DEPOSITOR' ? (
          <Input
            label="Доступно для вывода (всего)"
            type="number"
            register={() =>
              register('payment', {
                disabled: true,
              })
            }
          />
        ) : undefined}
      </form>
      {status !== 'CLOSED' ? (
        <div>
          <Button form="investor-form" type="submit">
            Сохранить
          </Button>
          {!forCreate ? (
            <div className={styles.buttons}>
              <Button onClick={() => setNewOperation('INPUT')}>Ввод</Button>
              <Button onClick={() => setNewOperation('OUTPUT')}>Вывод</Button>
            </div>
          ) : undefined}
        </div>
      ) : undefined}
      {investorId && investorType === 'DEPOSITOR' ? (
        <div className={styles.dividends}>
          <RecordTable
            title={
              <AddTitle
                text="Дивиденды"
                disabled={status === 'CLOSED'}
                onAddClick={() => toggleNewDividend(true)}
              />
            }
            disabled={!(investorId && investorType === 'Вкладчик')}
            columns={[
              { name: 'period_start', title: 'С', type: 'date' },
              { name: 'period_end', title: 'По', type: 'date' },
              { name: 'value', title: 'Сумма', type: 'number' },
            ]}
            forceUpdate={newDividend}
            resourcePath={
              investorId
                ? `/investors/${investorId || -1}/dividends`
                : undefined
            }
          />
          <EditModal
            isOpen={!!newDividend}
            close={() => toggleNewDividend(false)}
          >
            <Dividend
              investorId={investorId}
              close={() => toggleNewDividend(false)}
            />
          </EditModal>
        </div>
      ) : undefined}
      <EditModal
        isOpen={!!newOperation}
        close={() => setNewOperation(undefined)}
      >
        <Operation
          close={() => setNewOperation(undefined)}
          newOperation={newOperation}
          investorId={investorId}
        />
      </EditModal>
    </ComponentContainer>
  );
};

export default Investor;
