import ReactModal from 'react-modal';
import styles from './list.module.css';
import React, { useEffect, useState } from 'react';
import { createRecord, requestTable } from '../../../transport/api';
import Button from '../../../components/button';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

const PaymentManagementModal = ({ isOpen, close }) => {
  const [data, setData] = useState([]);
  const [loading, toggleLoading] = useState(true);
  const [update, toggleUpdate] = useState(false);

  useEffect(() => {
    requestTable('/order-operations/auto-operations', [], [{id: 'monday', desc: true}], 1, 300)
      .then((data) => {
        setData(data.list.reduce((acc, item) => {
          if (!Object.keys(acc).includes(item.sunday.split('-')?.[0])) {
            acc[item.sunday.split('-')?.[0]] = []
          }
          acc[item.sunday.split('-')?.[0]].push(item);
          return acc;
        }, {}));
        toggleLoading(false);
      });
  }, [update]);

  return (
    <ReactModal
      isOpen={isOpen}
      className={`${styles['modal-container']} ${styles['payment-modal']}`}
      overlayClassName={styles['modal-overlay']}
      ariaHideApp={false}
      onRequestClose={() => close()}
    >
      <div className={styles['operation-container']}>
        {loading ? new Array(20).fill(null).map(() => (
          <Skeleton inline height="2.5em" borderRadius="5px"/>
        )) : Object.entries(data).sort(([year1, _], [year2, __]) => year1 > year2 ? -1 : 1).map(([year, items]) => (
          <div key={year} className={styles['operation-container']}>
            {year}
            {items.map((item) => (
              <Button
                key={item.id}
                className={styles.operation}
                disabled={item.has_operations}
                onClick={() => {
                  toggleLoading(true);
                  createRecord('/order-operations/create-auto-operations', { sunday: item.sunday }).then(() => {
                    toggleUpdate(!update);
                    toast.success('Операции успешно созданы 🎉')
                  })
                }}
              >
                <div style={{display: 'flex', gap: '.5em', justifyContent: 'center'}}>
                  <div key="description">{item.description}</div>
                  {item.has_operations ? (
                    <div
                      key="secret delete"
                      onContextMenu={(e) => {
                        e.preventDefault();
                        if (window.confirm('סייג לחכמה שתיקה')) {
                          createRecord('/order-operations/delete-auto-operations', { sunday: item.sunday }).then(() => {
                            toggleUpdate(!update);
                            toast.success('Операции успешно удалены 🎉')
                          });
                        }
                      }}
                    >
                      🔒
                    </div>
                  ): undefined}

                </div>
              </Button>))}
          </div>
        ))}
      </div>
    </ReactModal>
  )
}

export default PaymentManagementModal;
