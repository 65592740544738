import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createRecord } from '../../transport/api';
import Input from '../../components/input';

import ComponentContainer from '../../components/ComponentContainer/index';
import Button from '../../components/button/index';
import { toast } from 'react-toastify';
import UncontrolledSelect from './../../components/UncontrolledSelect/index';

const Quantity = ({ supplyId, forCreate, close, remainingVolume }) => {
  const { register, handleSubmit, control, setValue } = useForm({ defaultValues: {} });

  const [remainingQuantity, setRemainingQuantity] = useState();
  const [quantityLabel, setQuantityLabel] = useState('Объём');
  const [isButtonDisabled, toggleButtonDisabled] = useState(false);

  const toastId = React.useRef(null);
  
  const handleQuantityChange = (e) => {
    toast.dismiss(toastId.current);
    if (e.target.value > Math.min(remainingVolume, remainingQuantity)) {
      setQuantityLabel('Объём⚠️');
      toast.warn('Превышен объём поставки');
    } else {
      setQuantityLabel('Объём');
    }
  };

  const onSubmit = async (data) => {
    toggleButtonDisabled(true);
    if (forCreate) {
      return createRecord(`/supplies/${supplyId}/quantities/new`, data)
        .then(() => {
          toast.success('Поставка успешно добавлена');
          close();
        })
        .catch((error) => toast.error(error.message))
        .finally(() => toggleButtonDisabled(false));
    }
  };

  return (
    <ComponentContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="quantity-form">
        <UncontrolledSelect
          control={control}
          name="shipment_id"
          label="Поставка"
          resourcePath={`/supplies/${supplyId}/shipments`}
          optionsColumns={{ value: 'id', label: 'name' }}
          disabled={!forCreate}
          additionalOnChange={(option) => {
            setValue('value', Math.min(option.remaining_quantity, remainingVolume));
            setRemainingQuantity(option.remaining_quantity);
          }}
        />
        <Input
          label={quantityLabel}
          type='number'
          options={{ min: 0 }}
          register={() =>
            register('value', {
              required: true,
              disabled: !forCreate,
              onChange: handleQuantityChange,
            })
          }
        />
      </form>
      <Button form="quantity-form" type="submit" disabled={forCreate && isButtonDisabled}>
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default Quantity;
