import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createRecord, requestEntity, updateRecord } from '../../transport/api';
import Input from '../../components/input';

import ComponentContainer from '../../components/ComponentContainer/index';
import Button from '../../components/button/index';
import { toast } from 'react-toastify';
import UncontrolledSelect from './../../components/UncontrolledSelect/index';

const Price = ({ supplyId, priceId, forCreate, close, supplyVolume }) => {
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: async () =>
      !forCreate ? requestEntity(`/supplies/${supplyId}/prices`, priceId) : {},
  });
  const [isButtonDisabled, toggleButtonDisabled] = useState(false);

  const onSubmit = async (data) => {
    toggleButtonDisabled(true)
    if (forCreate) {
      return createRecord(`/supplies/${supplyId}/prices/new`, data)
        .then(() => {
          toast.success('Затрата успешно добавлена');
          close();
        })
        .catch((error) => toast.error(error.message))
        .finally(() => toggleButtonDisabled(false));
    }
    return updateRecord(`/supplies/${supplyId}/prices/${priceId}/attach`, data)
      .then(() => {
        toast.success('Данные успешно обновлены');
        close();
      })
      .catch((error) => toast.error(error.message))
      .finally(() => toggleButtonDisabled(false));
  };

  return (
    <ComponentContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="price-form">
        <Input
          label="Описание"
          register={() => register('description', { required: true, disabled: !forCreate })}
        />
        <UncontrolledSelect
          control={control}
          name="operation_id"
          label="Операция"
          resourcePath={`/supplies/${supplyId}/operations`}
          optionsColumns={{ value: 'id', label: 'description' }}
          disabled={!forCreate}
          additionalOnChange={(option) => {
            if (option.volume && option.shipment_count) {
              setValue('value', (option.volume && option.shipment_count) ? (option.volume * (supplyVolume / option.shipment_count)).toFixed(2) : undefined)
              setValue('description', option.description)
            }
          }}
        />
        <Input
          label="Сумма"
          type="number"
          options={{ min: 0 }}
          register={() => register('value', { required: true, disabled: true })}
        />
      </form>
      <Button form="price-form" type="submit" disabled={isButtonDisabled}>
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default Price;
