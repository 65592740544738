import React from 'react';
import { useForm } from 'react-hook-form';
import { requestStatistic, updateRecord } from '../../../transport/api';
import { toast } from 'react-toastify';
import ComponentContainer from '../../../components/ComponentContainer';
import UncontrolledSelect from '../../../components/UncontrolledSelect';
import Button from '../../../components/button';
import styles from './card.module.css';


const LinkVariantsForm = ({ goodsId, close }) => {
  const { handleSubmit, control } = useForm({
    defaultValues: () => requestStatistic(`/goods/${goodsId}/goods-variants/selected`)
  });
  const onSubmit = async (data) => {
    updateRecord(`/goods/${goodsId}/goods-variants`, data)
      .then((responseData) => {
        toast.success('Артикулы успешно привязаны');
        close();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer title="Привязка артикулов" className={styles['link-container']}>
      <form onSubmit={handleSubmit(onSubmit)} id="variants-form">
        <UncontrolledSelect
          control={control}
          name="goods_variant_ids"
          label='Артикулы'
          resourcePath={`/goods/${goodsId}/goods-variants`}
          isMulti
          optionsColumns={{ value: 'id', label: 'vendor_code' }}
        />
      </form>
      <Button form="variants-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
}

export default LinkVariantsForm;