import React, { useState } from 'react';

import { makeTitle } from '../../../utils';
import AddTitle from '../../../components/AddTitle';
import AppContainer from '../../../components/app-container';
import AppTable from '../../../components/AppTable';
import { useNavigate } from 'react-router-dom';
import EditModal from '../../../components/EditModal';
import NewSeller from './NewSeller';
import styles from './list.module.css';


const Sellers = () => {
  makeTitle('Кабинеты');
  const navigate = useNavigate();

  const [isNewSeller, toggleNewSeller] = useState(false);

  return (
    <AppContainer
      title={<AddTitle text='Кабинеты' onAddClick={() => toggleNewSeller(true)} />}
    >
      <AppTable
        columns={[
          { name: 'name', title: 'Название'},
          { name: 'initialized', title: 'Инициализирован?', type: 'forceBool' },
        ]}
        onClick={(row) => navigate(`/sellers/${row.id}`)}
        resourcePath='/sellers'
        defaultSorting={[{ id: 'name', desc: true }]}
      />
      <EditModal close={() => toggleNewSeller(false)} isOpen={isNewSeller} className={styles['new-seller']}>
        <NewSeller />
      </EditModal>
    </AppContainer>
    )
}

export default Sellers;