import React from 'react';
import Cookies from 'js-cookie'
import { useNavigate, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import Input from '../../components/input';
import Button from '../../components/button';

import styles from './Login.module.css'
import { login } from '../../transport/api';
import { makeTitle } from '../../utils';

const Login = () => {
  makeTitle('Вход')
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    login(data.identifier, data.password)
      .then(() => navigate('/'))
      .catch((error) => {
        toast.error(error.message, { position: 'bottom-left' })
      }); 
  }

  return Cookies.get('refreshToken') ? <Navigate to='/' /> : (
    <div className={styles.container}>
      <div className={styles['form-container']}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <img src='./logo.png' alt='logo' className={styles.logo} />
          <div className={styles.title}>Войдите в свой аккаунт</div>
          <Input label='Электронная почта' placeholder='example@mail.com' register={() => register('identifier', { required: true })} />
          <Input label='Пароль' type='password' placeholder='••••••••' register={() => register('password', { required: true })} />
          {/*<div className={styles.reset} to='/reset' >Сбросить пароль?</div>*/}
          <Button type='submit'>Войти</Button>
        </form>
      </div>
      <div className={styles.picture}>
        <img src='./login.png' alt='login' />
      </div>
    </div>
  )
}

export default Login;
