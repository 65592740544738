import React from 'react';
import { Navigate } from 'react-router';
import { logout } from './../../transport/api';

const Logout = () => {
  logout();
  return <Navigate to='/login' />;
}

export default Logout;
