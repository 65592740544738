import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createRecord, deleteRecord } from '../../transport/api';

import AppContainer from '../../components/app-container';

import { requestEntity } from './../../transport/api';
import RecordTable from '../../components/RecordTable';
import AddTitle from '../../components/AddTitle';
import EditModal from './../../components/EditModal/index';
import SupplyEdit from './SupplyEdit';
import Price from './Price';
import Quantity from './Quantity';
import Loss from './Loss';
import Indicators from './Indicators';
import EmojiButton from '../../components/EmojiButton';
import AppTitle from '../../components/AppTitle';
import Switch from '../../components/Switch';

import styles from './Supply.module.css';

import { makeTitle } from '../../utils';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/ConfirmationModal';


const Supply = () => {
  const { supplyId } = useParams();

  const navigate = useNavigate();
  const [editingPriceId, setEditingPriceId] = useState();
  const [isNewQuantity, toggleNewQuantity] = useState(false);
  const [editingLossId, setEditingLossId] = useState();
  const [reload, setReload] = useState(false);
  const [data, setData] = useState();
  const [useInTurnover, setUseInTurnover] = useState(undefined);
  const [isSupplyEdit, toggleSupplyEdit] = useState(false);
  const [isConfirmDelete, toggleConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (useInTurnover) {
      createRecord(
        `/supplies/${supplyId}/set_use_in_turnover`,
        { 'use_in_turnover': useInTurnover },
      ).then(() => {
        useInTurnover === 'false'
          ? toast.success('Убрано из оборота')
          : toast.success('Включено в оборот');
        setReload(true);
      });
    }
  }, [useInTurnover, supplyId]);

  useEffect(() => {
    if (supplyId !== 'new') {
      requestEntity('/supplies', supplyId).then((data) => {
        setData(data);
        setLoading(false);
        setReload(false);
      });
    }
  }, [supplyId, editingPriceId, isNewQuantity, editingLossId, reload, isConfirmDelete]);

  useEffect(() => {
    const message = sessionStorage.getItem('updateSupply');
    if (message) {
      toast.success(message);
      sessionStorage.removeItem('updateSupply');
    }
  });

  makeTitle(data?.name);

  return data ? (
    <AppContainer
      title={
        <div className={styles['button-container']}>
          <div className={styles['button-container']}>
            <AppTitle loading={loading}>{data?.name}</AppTitle>
            <EmojiButton
              onClick={() => toggleSupplyEdit(true)}
              className={styles.button}
              emoji={'✏️'}
              disabled={loading}
            />
            <EmojiButton
              onClick={() => toggleConfirmDelete(true)}
              className={styles.button}
              emoji={'🗑️'}
              disabled={loading || !data?.can_delete}
              title={
                data?.can_delete
                  ? 'Удаление партии'
                  : 'Чтобы удалить партию, к ней не должны быть привязаны затраты, поставки и списания'
              }
            />
          </div>
          <div className={styles['button']}>
            <Switch
              value={`${data?.use_in_turnover}`}
              onChange={(e) => setUseInTurnover(e.target.value)}
              options={[
                { value: 'true', label: 'В обороте' },
                { value: 'false', label: 'Не в обороте' },
              ]}
            />
          </div>
        </div>
      }
    >
      <div className={styles['top-container']}>
        <Indicators
          volume={data?.volume}
          remainingVolume={data?.remaining_volume}
          hiddenVolume={data?.hidden_volume}
          costPrice={data?.cost_price}
          vendorCode={data?.vendor_code}
          date={
            data?.supply_date &&
            new Date(data?.supply_date).toLocaleDateString()
          }
        />
        <RecordTable
          title={
            <AddTitle
              text="Затраты"
              onAddClick={() => setEditingPriceId('new')}
            />
          }
          columns={[
            { name: 'value', title: 'Сумма, ₽', type: 'number' },
            { name: 'description', title: 'Описание' },
            { name: 'operation_date', title: 'Дата операции', type: 'datetime' },
          ]}
          resourcePath={`/supplies/${supplyId}/prices`}
          forceUpdate={editingPriceId}
          defaultSorting={[{ id: 'operation_date', desc: true }]}
          // onEditClick={(id) => setEditingPriceId(id)}
          getDeleteProperties={(row) => (
            { path: `/supplies/${supplyId}/prices/${row.id}`, callback: () => setReload(!reload) }
          )}
          // onLinkClick={(row) => console.log(row)}
        />
      </div>
      <div className={styles.container}>
        <RecordTable
          title={
            <AddTitle
              text="Поставки"
              onAddClick={() => toggleNewQuantity(true)}
            />
          }
          columns={[
            { name: 'shipment_date', title: 'Дата поставки', type: 'date' },
            { name: 'warehouse_name', title: 'Склад поставки' },
            { name: 'value', title: 'Объём, шт.', type: 'number' },
            {
              name: 'quantity_value_gt_volume',
              title: 'Превышен объём поставки?',
              align: 'center',
            },
          ]}
          defaultSorting={[{ id: 'shipment_date', desc: true }]}
          forceUpdate={isNewQuantity}
          resourcePath={`/supplies/${supplyId}/quantities`}
          getDeleteProperties={(row) => (
            { path: `/supplies/${supplyId}/quantities/${row.id}`, callback: () => setReload(!reload) }
          )}
          // onLinkClick={(row) => console.log(row)}
        />
        <RecordTable
          title={
            <AddTitle
              text="Списания"
              onAddClick={() => setEditingLossId('new')}
            />
          }
          columns={[
            { name: 'cause', title: 'Причина' },
            { name: 'volume', title: 'Объём, шт.', type: 'number' },
          ]}
          forceUpdate={editingLossId}
          onClick={(row) => setEditingLossId(() => row.id)}
          resourcePath={`/supplies/${supplyId}/losses`}
          getDeleteProperties={(row) => (
            { path: `/supplies/${supplyId}/losses/${row.id}`, callback: () => setReload(!reload) }
          )}
        />
      </div>
      <EditModal close={() => toggleSupplyEdit(false)} isOpen={isSupplyEdit}>
        <SupplyEdit
          isOpen={isSupplyEdit}
          supplyId={supplyId}
          supplyDate={data?.supply_date}
          name={data?.name}
          volume={data?.volume}
          hiddenVolume={data?.hidden_volume}
        />
      </EditModal>
      <EditModal
        isOpen={!!editingPriceId}
        close={() => setEditingPriceId(undefined)}
      >
        <Price
          supplyId={supplyId}
          priceId={editingPriceId}
          forCreate={editingPriceId === 'new'}
          close={() => setEditingPriceId(undefined)}
          supplyVolume={data?.volume}
        />
      </EditModal>
      <EditModal
        isOpen={!!isNewQuantity}
        close={() => toggleNewQuantity(false)}
      >
        <Quantity
          forCreate={isNewQuantity}
          supplyId={supplyId}
          close={() => toggleNewQuantity(false)}
          remainingVolume={data?.remaining_volume}
        />
      </EditModal>
      <EditModal
        isOpen={!!editingLossId}
        close={() => setEditingLossId(undefined)}
      >
        <Loss
          lossId={editingLossId}
          supplyId={supplyId}
          forCreate={editingLossId === 'new'}
          close={() => setEditingLossId(undefined)}
          remainingVolume={data?.remaining_volume}
        />
      </EditModal>
      <ConfirmationModal
        isOpen={isConfirmDelete}
        title="Вы уверены, что хотите удалить поствку?"
        onConfirm={() => deleteRecord(`/supplies/${supplyId}`)
          .then(() => {
            toast.success('Удалено 😬');
            navigate('/supplies');
          })
        }
        onCancel={() => toggleConfirmDelete(false)}
      />
    </AppContainer>
  ) : undefined;
};

export default Supply;
