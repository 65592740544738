import React from 'react';
import styles from './Cell.module.css';
import { formatInt } from '../../utils';

const getStyleByName = (style, rowData) => {
  if (style instanceof Function) {
    return `${styles['cell-rectangle']} ${style(rowData)}`;
  } else if (style === 'rectangle-blue') {
    return `${styles['cell-rectangle']} ${styles['cell-rectangle-blue']}`;
  } else if (style === 'rectangle-red') {
    return `${styles['cell-rectangle']} ${styles['cell-rectangle-red']}`;
  } else if (style === 'rectangle-green') {
    return `${styles['cell-rectangle']} ${styles['cell-rectangle-green']}`;
  }
  return '';
};

const renderValue = (value, type) => {
  if (type === 'text') {
    return value;
  }
  if (type === 'bool') {
    return value ? '✔️' : '';
  }
  if (type === 'forceBool') {
    return value ? '✔️' : value === false ? '❌' : '';
  }
  if (type === 'image') {
    return (
      <img src={value || '/icons/Image 2.svg'} alt="record-thumb"
           className={`${styles['cell-image']} ${!value ? styles['no-image'] : ''}`}/>
    );
  }
  if (type === 'date' && value !== undefined && value !== null) {
    return value && new Date(value).toLocaleDateString();
  }
  if (type === 'datetime' && value !== undefined && value !== null) {
    return value && new Date(value).toLocaleString();
  }
  if (type === 'number' && value !== undefined && value !== null) {
    return formatInt(value);
  }
};

const Cell = ({ style, rowData, children, type, align }) => (
  <div
    className={`${styles.cell} ${
      align === 'start' ? styles['cell-start'] :
        (
          align === 'end' || type === 'number'
        ) ? styles['cell-end'] : ''
    }`}
  >
    <div className={getStyleByName(style, rowData)}>{renderValue(children, type)}</div>
  </div>
);

export default Cell;