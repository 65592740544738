import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import AppContainer from '../../components/app-container';
import ComponentContainer from '../../components/ComponentContainer';

import styles from './Group.module.css';
import { createRecord, requestEntity, updateRecord } from '../../transport/api';
import Input from '../../components/input';
import Button from '../../components/button';
import { toast } from 'react-toastify';
import RecordTable from '../../components/RecordTable';
import AddTitle from '../../components/AddTitle';
import EditModal from './../../components/EditModal/index';
import Seller from './Seller';
import ApiKey from './ApiKey';
import { makeTitle } from '../../utils';


const Group = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();

  const [newSeller, toggleNewSeller] = useState(false);
  const [editingSellerId, setEditingSellerId] = useState();
  const [newApiKey, toggleNewApiKey] = useState(false);
  const [editingApiKeyId, setEditingApiKeyId] = useState();

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: async () => groupId !== 'new' ? requestEntity('/groups', groupId)
      .then((data) => (
        { ...data, id: data.id || groupId }
      ))
      .catch((_) => navigate(-1, { replace: true })) : {},
  });

  makeTitle(groupId === 'new' ? 'Новая группа' : getValues('name'));

  const onSubmit = async (data) => {
    if (groupId === 'new') {
      createRecord('/groups/new', data)
        .then((responseData) => {
          toast.success('Группа успешно создана');
          navigate(`/groups/${responseData.group_id}`);
        })
        .catch((error) => toast.error(error.message));
    } else {
      updateRecord(`/groups/${groupId}`, data)
        .then(() => toast.success('Данные успешно обновлены'))
        .catch((error) => toast.error(error.message));
    }
  };

  return (
    <AppContainer title={groupId === 'new' ? 'Новая группа' : getValues('name')}>
      <div className={styles.container}>
        <ComponentContainer title="Основная информация">
          <form onSubmit={handleSubmit(onSubmit)} id="group-form">
            <Input label="Имя" register={() => register('name',
              { required: true, disabled: groupId !== 'new' },
            )}/>
          </form>
          <Button form="group-form" type="submit">Сохранить</Button>
        </ComponentContainer>
        <RecordTable
          title="Пользователи"
          columns={[
            // { name: 'image_url', title: '' },
            { name: 'fullname', title: 'ФИО' },
            { name: 'email', title: 'Электронная почта' },
            { name: 'is_admin', title: 'Администратор?', type: 'bool' },
          ]}
          resourcePath={`/groups/${groupId === 'new' ? -1 : groupId}/users`}
        />
        <RecordTable
          title={<AddTitle text="Селлеры" onAddClick={() => toggleNewSeller(true)}/>}
          columns={[
            { name: 'name', title: 'Имя' },
            { name: 'initialized', title: 'Инициализирован?', type: 'bool' },
          ]}
          forceUpdate={editingSellerId || newSeller}
          onClick={(row) => setEditingSellerId(() => row.id)}
          resourcePath={`/groups/${groupId === 'new' ? -1 : groupId}/sellers`}
        />
        <RecordTable
          title={<AddTitle text="API-ключи" onAddClick={() => toggleNewApiKey(true)}/>}
          columns={[
            { name: 'seller_name', title: 'Селлер' },
            { name: 'api_key_type_desc', title: 'Тип' },
          ]}
          forceUpdate={editingApiKeyId || newApiKey}
          onClick={(row) => setEditingApiKeyId(() => row.id)}
          resourcePath={`/groups/${groupId === 'new' ? -1 : groupId}/apikeys`}
        />
        <EditModal
          isOpen={!!editingSellerId || newSeller}
          close={() => {
            setEditingSellerId(undefined);
            toggleNewSeller(false);
          }}
        >
          <Seller
            groupId={groupId}
            sellerId={editingSellerId}
            forCreate={newSeller}
            close={() => {
              setEditingSellerId(undefined);
              toggleNewSeller(false);
            }}
          />
        </EditModal>
        <EditModal
          isOpen={!!editingApiKeyId || newApiKey}
          close={() => {
            setEditingApiKeyId(undefined);
            toggleNewApiKey(false);
          }}
        >
          <ApiKey
            groupId={groupId}
            apiKeyId={editingApiKeyId}
            forCreate={newApiKey}
            close={() => {
              setEditingApiKeyId(undefined);
              toggleNewApiKey(false);
            }}
          />
        </EditModal>
      </div>
    </AppContainer>
  );
};

export default Group;