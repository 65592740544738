import React from 'react';

import styles from './AppTable.module.css'
import CoreTable from './../CoreTable/index';


const AppTable = ({
  columns,
  resourcePath,
  filters,
  onClick,
  forceUpdate,
  defaultSorting = [],
  onEditClick,
  getDeleteProperties,
  onLoad,
}) => {
  return (
    <CoreTable
      columns={columns}
      resourcePath={resourcePath}
      filters={filters}
      onClick={onClick}
      forceUpdate={forceUpdate}
      rowClassName={styles.row}
      defaultSorting={defaultSorting}
      onEditClick={onEditClick}
      getDeleteProperties={getDeleteProperties}
      onLoad={onLoad}
    />
  )
}

export default AppTable;
