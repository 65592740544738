import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDate, makeTitle } from '../../../utils';

import AppContainer from '../../../components/app-container';
import AppTable from '../../../components/AppTable';
import Input from '../../../components/input';

import styles from './list.module.css'
import TriggerModal from '../../../components/TriggerModal';
import EmojiButton from '../../../components/EmojiButton';
import Select from '../../../components/Select';
import Switch from '../../../components/Switch';

const Turnover = () => {
  makeTitle('Оборачиваемость');
  const [searchParams, setSearchParams] = useSearchParams();
  const [periodStart, setPeriodStart] = useState(getDate('date', -14));
  const [periodEnd, setPeriodEnd] = useState(getDate('date'));

  const [goodsVariantId, setGoodsVariantId] = useState();

  const [showHidden, toggleShowHidden] = useState(false)

  const [submitPeriodStart, setSubmitPeriodStart] = useState(periodStart);
  const [submitPeriodEnd, setSubmitPeriodEnd] = useState(periodEnd);
  const [isTriggerSetShow, toggleTriggerSetShow] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.has('turnoverType')) {
      setSearchParams({ turnoverType: 'goods_variants' })
    }
  }, [])

  return (
    <AppContainer
      title={
          <div className={styles['button-container']}>
            Оборачиваемость
            <Switch
              isFilter
              containerClassName={styles['turnover-type-switch']}
              value={searchParams.get('turnoverType')}
              onChange={(e) => {
                setSearchParams({ turnoverType: e.target.value });
              }}
              options={[
                { value: 'goods_variants', label: 'Артикулы' },
                { value: 'warehouses', label: 'Склады' },
              ]}
            />
            {searchParams.get('turnoverType') === 'goods_variants' ? <EmojiButton
              emoji="💢"
              className={styles['trigger-button']}
              onClick={() => toggleTriggerSetShow(true)}
              title="Установить триггеры"
            /> : undefined}
          </div>
      }
      filters={[
        searchParams.get('turnoverType') === 'goods_variants' ? <EmojiButton
          emoji={showHidden ? '🐵' : '🙈'}
          title="Показать скрытые артикулы"
          onClick={() => {
            toggleShowHidden(!showHidden)
          }}
        /> : undefined,
        <div>Среднее за период:</div>,
        <Input
          type="date"
          value={periodStart}
          onChange={(e) => setPeriodStart(() => e.target.value)}
          options={{
            onBlur: () => setSubmitPeriodStart(periodStart)
          }}
          isFilter
        />,
        <div>—</div>,
        <Input
          type="date"
          value={periodEnd}
          onChange={(e) => setPeriodEnd(() => e.target.value)}
          options={{
            onBlur: () => setSubmitPeriodEnd(periodEnd)
          }}
          isFilter
        />,
        searchParams.get('turnoverType') === 'goods_variants' ? <Select
          value={goodsVariantId}
          onChange={(value) => setGoodsVariantId(() => value)}
          resourcePath="/goods-variants"
          optionsColumns={{ value: 'id', label: 'vendor_code' }}
          label="Артикул"
        /> : undefined,
      ]}
    >
      {searchParams.get('turnoverType') === 'goods_variants' ? (
        <AppTable
          columns={[
            { name: 'vendor_code', title: 'Артикул'},
            {
              name: 'turnover',
              title: 'Обор-ость общая',
              type: 'number',
              style: (row) => styles[row.color],
              customStyle: styles['fixed-column'],
            },
            {
              name: 'wb_turnover',
              title: 'Обор-ость WB',
              type: 'number',
              style: (row) => styles[row?.color_wb],
              customStyle: styles['fixed-column']
            },
            { name: 'problem', title: 'Проблемные склады', type: 'number', customStyle: styles['fixed-column'] },
            { name: 'all_remaining_volume', title: 'Остаток общий', type: 'number', customStyle: styles['fixed-column'] },
            { name: 'marketplace_units_left', title: 'Остаток WB', type: 'number', customStyle: styles['fixed-column'] },
            { name: 'period_orders', title: 'Заказы за период', type: 'number', customStyle: styles['fixed-column'] },
            { name: 'avg_period_orders', title: 'Средние заказы', type: 'number', customStyle: styles['fixed-column'] },
          ]}
          onClick={(row) => navigate(`/turnover/${row.id}`)}
          resourcePath="/turnover"
          defaultSorting={[{ id: 'turnover', desc: false }]}
          filters={[
            { fieldName: 'period_start', value: submitPeriodStart },
            { fieldName: 'period_end', value: submitPeriodEnd },
            { fieldName: 'hide_in_turnover', value: showHidden },
            { fieldName: 'goods_variant_id', value: goodsVariantId }
          ]}
          forceUpdate={isTriggerSetShow}
        />) : undefined}
      {searchParams.get('turnoverType') === 'warehouses' ? (
        <AppTable
          columns={[
            // { name: 'id', title: 'id'},
            { name: 'warehouse_name', title: 'Склад'},
            {
              name: 'dangerous_goods_variants',
              title: 'Кол-во артикулов в красной зоне',
            },
            {
              name: 'bad_goods_variants',
              title: 'Кол-во артикулов в жёлтой зоне',
            },
            {
              name: 'goods_variants',
              title: 'Всего артикулов',
            },
            {
              name: 'marketplace_units_left',
              title: 'Всего штук на складе',
            },
            {
              name: 'box_coefficient', title: 'Коэффициент для коробов'
            },
            {
              name: 'pallet_coefficient', title: 'Коэффициент для монопаллет'
            }
          ]}
          onClick={(row) => navigate(`/warehouse-turnover/${row.id}`)}
          resourcePath="/turnover/warehouse-turnover"
          defaultSorting={[{ id: 'marketplace_units_left', desc: true }]}
          filters={[
            { fieldName: 'period_start', value: submitPeriodStart },
            { fieldName: 'period_end', value: submitPeriodEnd },
          ]}
        />
      ) : undefined}
      <TriggerModal
        isOpen={isTriggerSetShow}
        close={() => toggleTriggerSetShow(false)}
        columns={[
          {
            key: 'vendor_code',
            title: 'Артикул'
          },
          {
            key: 'trigger',
            title: 'Триггер'
          },
          {
            key: 'trigger_mp',
            title: 'Триггер ВБ'
          },
          {
            key: 'hide',
            title: 'Скрыт?'
          },
        ]}
        url="/triggers"
      />
    </AppContainer>
  );
};

export default Turnover;
