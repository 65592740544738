import React from 'react';
import ReactECharts from 'echarts-for-react';
import { buildOptions } from './options';

import ComponentContainer from '../../ComponentContainer';

const LineChart = ({
  labels,
  data,
  title,
  startColor = '#5BC4FF',
  endColor = '#FF5BEF',
  itemsColor = '#AE8FF7',
  formatter = undefined,
}) => {
  return (
    <ComponentContainer title={title}>
      <ReactECharts
        style={{ height: '23em' }}
        option={buildOptions(labels, data, startColor, endColor, itemsColor, formatter)}
      />
    </ComponentContainer>
  );
};

export default LineChart;
