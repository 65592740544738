import React, { useState } from 'react';
import ReactModal from 'react-modal';
import ConfirmationModal from '../ConfirmationModal';

import styles from './EditModal.module.css';


const EditModal = ({isOpen, buttons, children, close, confirmationTitle = 'Вы уверены, что хотите отменить изменения?', className = ''}) => {
  const [showConfirmation, toggleShowConfirmation] = useState(false);
  return (
    <ReactModal
      className={`${styles.container} ${className}`}
      overlayClassName={styles.overlay}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={() => toggleShowConfirmation(() => true)}
    >
      {children}
      <ConfirmationModal
        isOpen={showConfirmation}
        title={confirmationTitle}
        confirmTitle='Да'
        cancelTitle='Нет'
        onCancel={() => toggleShowConfirmation(() => false)}
        onConfirm={() => {
          toggleShowConfirmation(() => false);
          close();
        }}
      />
    </ReactModal>
  )
}

export default EditModal;
