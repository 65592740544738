import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'

import { getDate } from '../../utils';
import { createRecord, requestStatistic } from '../../transport/api';

import ComponentContainer from '../../components/ComponentContainer';
import UncontrolledSelect from '../../components/UncontrolledSelect';
import Button from '../../components/button';
import Input from '../../components/input';

const ShipmentCreateForm = () => {
  const [groupId, setGroupId] = useState();

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      shipment_date: getDate(),
      close_date: getDate(),
    }
  });

  const onSubmit = async (data) => {
    createRecord('/shipments/new', data)
      .then(() => {
        sessionStorage.setItem('createShipment', 'Поставка успешно создана');
        window.location.reload(false);
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer title='Основная информация'>
      <form onSubmit={handleSubmit(onSubmit)} id='shipment-form'>
        <Input
          label='Идентификатор WB'
          type='number'
          options={{ min: 0 }}
          register={() => register('wb_income_id', { required: true })}
        />
        <UncontrolledSelect
          control={control}
          name='vendor_code'
          label='Артикул'
          resourcePath='/goods-variants'
          loadOnFocus
          optionsColumns={{ value: 'vendor_code', label: 'vendor_code' }}
          additionalOnChange={(option) => {
            if (option.goods_id) {
              requestStatistic(`/goods/${option.goods_id}/info`, []).then(
                (data) => {
                  setValue('seller_id', data.seller_id);
                  setGroupId(data.group_id);
                  setValue('group_id', data.group_id);
                }
              );
            }
          }}
        />
        <UncontrolledSelect
          control={control}
          name='warehouse_name'
          label='Склад'
          resourcePath="/warehouses"
          loadOnFocus
          optionsColumns={{ value: 'name', label: 'name' }}
          disabled={!(Cookies.get('groupId') || groupId)}
          creatable={true}
        />
        <Input
          label='Количество'
          type='number'
          options={{ min: 0 }}
          register={() => register('volume', { required: true })}
        />
        <Input
          label='Дата Поставки'
          type='datetime-local'
          register={() => register('shipment_date', { required: true })}
        />
        <Input
          hidden
          value='Пользовательская'
          register={() => register('status', { required: true })}
        />
        <Input
          type='number'
          hidden
          register={() => register('seller_id', { required: true })}
        />
        <Input
          type='number'
          hidden
          register={() => register('group_id', { required: true })}
        />
      </form>
      <Button form='shipment-form' type='submit'>
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default ShipmentCreateForm;
