import React from 'react';
import ComponentContainer from '../../components/ComponentContainer';
import Input from '../../components/input';

import styles from './Audit.module.css';

const AuditInformation = ({ auditRecord, close }) => {
  return (
    <ComponentContainer>
      <form id="audit-form">
        <Input
          label="Имя пользователя"
          defaultValue={auditRecord?.fullname}
          disabled={true}
        />
        <Input
          label="Дата изменений"
          defaultValue={auditRecord?.change_date?.slice(0, 16)}
          type='datetime-local'
          disabled={true}
        />
        <Input
          label="URI"
          defaultValue={auditRecord?.uri}
          disabled={true}
        />
        <Input
          label="Метод"
          defaultValue={auditRecord?.methodname}
          disabled={true}
        />
        <div>
          <div>Данные</div>
          <div className={styles['input']}>
            <pre>
              {auditRecord ? JSON.stringify(JSON.parse(auditRecord?.data), null, 2) : ''}
            </pre>
          </div>
        </div>
      </form>
    </ComponentContainer>
  );
};

export default AuditInformation;
