import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { createRecord } from '../../../transport/api';
import { toast } from 'react-toastify';
import ComponentContainer from '../../../components/ComponentContainer';
import Input from '../../../components/input';
import Button from '../../../components/button';
import React from 'react';
import UncontrolledSelect from '../../../components/UncontrolledSelect';
import Cookies from 'js-cookie';


const NewGoods = () => {
  const { register, handleSubmit, control } = useForm();
  const navigate = useNavigate();
  const groupId = Cookies.get('groupId');
  const onSubmit = async (data) => {
    createRecord('/goods', data)
      .then((responseData) => {
        toast.success('Проект успешно создан');
        navigate(`/goods/${responseData.id}`);
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer title="Основная информация">
      <form onSubmit={handleSubmit(onSubmit)} id="goods-form">
        <Input
          label="Название"
          register={() =>
            register('name', {
              required: true,
            })
          }
        />
        <UncontrolledSelect
          control={control}
          name="seller_id"
          label="Селлер"
          resourcePath={`/groups/${groupId}/sellers`}
          loadOnFocus
          optionsColumns={{ value: 'id', label: 'name' }}
        />
      </form>
      <Button form="goods-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default NewGoods;
