import React from 'react';

import styles from './Header.module.css';

const Header = ({title, children}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {children ? (
        <div className={styles.filters}>
          {children.map((child, index) => <div key={index} className={styles.filter}>{child}</div>)}
        </div>
      ) : null}
    </div>
  );
}

export default Header;
