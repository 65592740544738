import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createRecord, requestEntity, requestTable, updateRecord } from '../../transport/api';
import Input from '../../components/input';

import ComponentContainer from '../../components/ComponentContainer/index';
import Button from '../../components/button/index';
import { toast } from 'react-toastify';
import UncontrolledSelect from '../../components/UncontrolledSelect';
import Switch from '../../components/Switch';
import { getDate } from '../../utils';
import Alert from '../../components/Alert';

import styles from './Operation.module.css';


const getDescriptionByType = (operationType, isInvestorOperation) => {
  if (isInvestorOperation) {
    return {
      OUTPUT: 'Регулярная выплата инвестору',
      INPUT: 'Внесение средств инвестором',
    }?.[operationType] ?? ''
  }
  return {
    INNER_TRANSFER: 'Внутренний перевод',
    TAX: 'Оплата налога',
    PAYMENT: 'Оплата партии',
    DEPOSIT: 'Ввод тела',
    WITHDRAW: 'Вывод тела',
  }?.[operationType] ?? '';
};

const Operation = ({
  close,
  newOperation,
  editingOperationId,
  investorId,
  defaultVolume,
  defaultFromBalanceName,
  defaultToBalanceName,
  onlyShared,
}) => {
  const [operationType, setOperationType] = useState(newOperation);
  const [isShared, toggleShared] = useState(onlyShared);
  const [operationInvestorId, setOperationInvestorId] = useState(investorId);
  const [loading, toggleLoading] = useState(false);

  const [balances, setBalances] = useState([]);
  const [physicalBalances, setPhysicalBalances] = useState([]);

  useEffect(() => {
    requestTable('/money-operations/balances', [], [], 1, 300).then((data) => {
      setBalances(() =>
        data?.list.map((item) => ({
          ...item,
          value: item.value,
          label: item.label,
        }))
      );
    });
    requestTable('/money-operations/physical-balances', [], [], 1, 300).then((data) => {
      setPhysicalBalances(() =>
        data?.list.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        }))
      );
    });
  }, [setBalances]);

  const { register, handleSubmit, control } = useForm({
    defaultValues: !editingOperationId
      ? {
        operation_type: newOperation,
        is_shared: (
          operationType === 'TAX' && onlyShared
        ) ? 'shared_true' : 'shared_false',
        investor_id: investorId,
        volume: defaultVolume,
        operation_date: getDate(),
        balance_from_name: defaultFromBalanceName,
        balance_to_name: defaultToBalanceName,
        description: getDescriptionByType(newOperation, !!investorId),
      }
      : async () =>
        requestEntity('/money-operations', editingOperationId).then(
          (data) => {
            toggleShared(data.is_shared);
            setOperationType(data.operation_type);
            setOperationInvestorId(data.investor_id);
            return {
              ...data,
              operation_date: data.operation_date.slice(0, 16),
              change_date: data.change_date.slice(0, 16),
              is_shared: data.is_shared
                ? 'shared_true'
                : 'shared_false',
            };
          },
        ),
  });

  const onSubmit = async (data) => {
    if (!editingOperationId) {
      toggleLoading(true);
      return createRecord(`/money-operations/new`, {
        ...data,
        is_shared: data.is_shared === 'shared_true' || isShared,
      })
        .then(() => {
          toast.success('Операция успешно добавлена');
          close();
        })
        .catch((error) => toast.error(error.message))
        .finally(() => toggleLoading(false));
    }
    return updateRecord(`/money-operations/${editingOperationId}`, {
      operation_date: data.operation_date,
      description: data.description,
    }).then(() => {
      toast.success('Операция успешно обновлена');
      close();
    }).catch((error) => toast.error(error.message))
      .finally(() => toggleLoading(false));
  };

  return (
    <ComponentContainer className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)} id="money-operations-form">
        <UncontrolledSelect
          control={control}
          name="operation_type"
          label="Тип операции"
          disabled
          resourcePath="/money-operations/types"
          optionsColumns={{ value: 'value', label: 'label' }}
        />
        {!(
          operationType === 'TAX' && onlyShared
        ) ? (
          <Input
            label="Сумма"
            type="number"
            options={{ min: 0.0 }}
            register={() =>
              register('volume', {
                required: true,
                disabled: !!editingOperationId,
              })
            }
          />
        ) : undefined}
        {operationType === 'TAX' ? <Alert>
          Для корретного списания налога, необходимо выбрать дату, месяц которой является следующей, после налогового
          периода. Например - если платятся налоги за III квартал, то необходимо выбрать дату в октябре.
        </Alert> : undefined}
        <Input
          label="Дата операции"
          type="datetime-local"
          register={() =>
            register('operation_date', {
              required: true,
              disabled: !newOperation && isShared,
            })
          }
        />
        {!(
          operationType === 'TAX' && onlyShared
        ) ? <Input
          label="Описание"
          register={() =>
            register('description', {
              required: true,
              disabled: !newOperation && isShared,
            })
          }
        /> : undefined}
        {operationType === 'PAYMENT' ? (
          <Input
            label="Штук в партии"
            type="number"
            options={{ min: 1 }}
            register={() =>
              register('shipment_count', {
                required: true,
                disabled: !!editingOperationId,
              })
            }
          />
        ) : undefined}
        {(
          operationType === 'OUTPUT' && !operationInvestorId && !defaultFromBalanceName
        ) || (
          operationType === 'TAX' && onlyShared
        ) ? (
          <Switch
            label="Общая операция?"
            options={[
              { value: 'shared_true', label: 'Да' },
              { value: 'shared_false', label: 'Нет' },
            ]}
            register={() =>
              register('is_shared', {
                disabled: !!editingOperationId || (
                  operationType === 'TAX' && onlyShared
                ),
                onChange: (e) => toggleShared(e.target.value === 'shared_true'),
              })
            }
          />
        ) : undefined}
        {['OUTPUT', 'INNER_TRANSFER', 'PAYMENT', 'TAX', 'DEPOSIT', 'WITHDRAW'].includes(operationType) &&
        !isShared &&
        !(
          newOperation && operationInvestorId
        ) ? (
          <UncontrolledSelect
            control={control}
            name="balance_from_name"
            label="Баланс списания"
            customOptions={balances}
            disabled={!!editingOperationId || !!defaultFromBalanceName}
          />
        ) : undefined}
        {['OUTPUT', 'INNER_TRANSFER', 'PAYMENT', 'TAX', 'DEPOSIT', 'WITHDRAW'].includes(operationType) && !isShared ? (
          <UncontrolledSelect
            control={control}
            name="physical_balance_from_id"
            label="Физический баланс списания"
            customOptions={physicalBalances}
            disabled={!!editingOperationId}
          />
        ) : undefined}
        {['INNER_TRANSFER', 'INPUT', 'DEPOSIT', 'WITHDRAW'].includes(operationType) &&
        !(
          newOperation && operationInvestorId
        ) ? (
          <UncontrolledSelect
            control={control}
            name="balance_to_name"
            label="Баланс зачисления"
            customOptions={balances}
            disabled={!!editingOperationId || !!defaultToBalanceName}
          />
        ) : undefined}
        {['INNER_TRANSFER', 'INPUT', 'DEPOSIT', 'WITHDRAW'].includes(operationType) ? (
          <UncontrolledSelect
            control={control}
            name="physical_balance_to_id"
            label="Физический баланс зачисления"
            customOptions={physicalBalances}
            disabled={!!editingOperationId}
          />
        ) : undefined}
        {['INPUT', 'OUTPUT'].includes(operationType) &&
        !!operationInvestorId ? (
          <UncontrolledSelect
            control={control}
            name="investor_id"
            label="Инвестор"
            resourcePath="/money-operations/investors"
            optionsColumns={{ value: 'value', label: 'label' }}
            disabled={true}
          />
        ) : undefined}
      </form>
      <Button form="money-operations-form" type="submit" disabled={loading}>
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default Operation;
