import React from 'react';
import { useForm } from 'react-hook-form';
import { createRecord, requestEntity, updateRecord } from './../../../transport/api';
import Input from '../../../components/input';
import UncontrolledSelect from '../../../components/UncontrolledSelect';

import ComponentContainer from './../../../components/ComponentContainer/index';
import Button from './../../../components/button/index';
import { toast } from 'react-toastify';

const ApiKey = ({ apiKeyId, groupId, forCreate, close }) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: async () => !forCreate ? requestEntity(`/groups/${groupId}/apikeys`, apiKeyId) : {}
  });

  const onSubmit = async (data) => {
    if (forCreate) {
      return createRecord(`/groups/${groupId}/apikeys/new`, data)
        .then(() => {
          toast.success('API-ключ успешно добавлен');
          close();
        })
        .catch((error) => toast.error(error.message))
    }
    return updateRecord(`/groups/${groupId}/apikeys/${apiKeyId}`, data)
      .then(() => {
        toast.success('Данные API-ключа успешно обновлены');
        close();
      })
      .catch((error) => toast.error(error.message))
  };

  return (
      <ComponentContainer>
        <form onSubmit={handleSubmit(onSubmit)} id='user-form'>
          <UncontrolledSelect control={control} name='api_key_type' label='Тип ключа' resourcePath={`/groups/${groupId}/apikeys/types`} optionsColumns={{ value: 'name', label: 'label' }} />
          <UncontrolledSelect control={control} name='seller_id' label='Селлер' resourcePath={`/groups/${groupId}/sellers`} optionsColumns={{ value: 'id', label: 'name' }} />
          <Input label='Ключ' type='password' placeholder='••••••••' register={() => register('key', { required: true })} />
        </form>
        <Button form='user-form' type='submit'>Сохранить</Button>
      </ComponentContainer>
  )
}

export default ApiKey;
