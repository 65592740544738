import React from 'react';
import { useForm } from 'react-hook-form';
import { callAction, createRecord, requestEntity, updateRecord } from './../../../transport/api';
import Input from '../../../components/input';

import ComponentContainer from './../../../components/ComponentContainer/index';
import Button from './../../../components/button/index';
import { toast } from 'react-toastify';

import styles from '../Group.module.css';

const Seller = ({ groupId, sellerId, forCreate, close }) => {
  const { register, handleSubmit, getValues } = useForm({
    defaultValues: async () => !forCreate ? requestEntity(`/groups/${groupId}/sellers`, sellerId) : {}
  });

  const onSubmit = async (data) => {
    if (forCreate) {
      return createRecord(`/groups/${groupId}/sellers/new`, data)
        .then(() => {
          toast.success('Селлер успешно добавлен');
          close();
        })
        .catch((error) => toast.error(error.message))
    }
    return updateRecord(`/groups/${groupId}/sellers/${sellerId}`, data)
      .then(() => {
        toast.success('Данные селлера успешно обновлены');
        close();
      })
      .catch((error) => toast.error(error.message))
  };

  return (
      <ComponentContainer>
        <form onSubmit={handleSubmit(onSubmit)} id='seller-form'>
          <Input label='Название' register={() => register('name', { required: true })} />
        </form>
        <div className={styles.buttons}>
          {!forCreate && !getValues('initialized') ? <Button onClick={() => callAction(`/groups/${groupId}/sellers/${sellerId}`).then((data) => toast.success('Инициализация началась'))}>Переинициализировать</Button> : undefined}
          <Button form='seller-form' type='submit'>Сохранить</Button>
        </div>
      </ComponentContainer>
  )
}

export default Seller;
