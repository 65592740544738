import axios from 'axios';
import Cookies from 'js-cookie'
import { toast } from 'react-toastify';

import { HttpError } from '../errors';
import { ResponseError } from './Errors';

export const apiUrl = process.env.REACT_APP_API_URL;

export const API = axios.create();

export const updateAccessToken = async () => {
  return API.post(
    `${apiUrl}/auth/access-token`,
    {},
    {
      headers: {
        Authorization: `Bearer ${Cookies.get('refreshToken')}`,
      },
      validateStatus: (status) => status < 400,
    },
  )
    .then((resp) => Cookies.set('accessToken', resp.data.access_token))
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error('Сессия истекла, повторите вход');
        // logout();
        window.location.href = '/logout'
        return Promise.reject(new HttpError('Сессия истекла'));
      }
      return Promise.reject(new HttpError('Неизвестная ошибка авторизации'));
    });
}

export const login = async (identifier, password) => {
  return API.post(`${apiUrl}/auth/login`, { identifier, password }, { validateStatus: (status) => status < 400 })
    .then((resp) => {
      if (resp?.data?.refresh_token) {
        Cookies.set('refreshToken', resp.data.refresh_token);
        Cookies.set('accessToken', resp.data.access_token);
        Cookies.set('userId', resp.data.user_id);
        Cookies.set('fullName', resp.data.fullname);
        Cookies.set('isAdmin', resp.data.is_admin);
        Cookies.set('groupName', resp.data.groupname);
        Cookies.set('groupId', resp.data.group_id);
      } else {
        return Promise.reject(new HttpError('Неизвестная ошибка авторизации'));
      }
    })
    .catch((error) => {
      if ([400, 401, 404].includes(error?.response?.status)) {
        return Promise.reject(new HttpError('Неверное имя пользователя или пароль'));
      }
      return Promise.reject(new HttpError('Неизвестная ошибка авторизации'));
    });
}

export const logout = () => {
  [
    'refreshToken',
    'accessToken',
    'userId',
    'fullName',
    'isAdmin',
    'groupName',
  ].forEach((cookieName) => Cookies.remove(cookieName));
}

export const request = async (method, url, data = {}, _try = 0, isFileUpload = false) => {
  const headers = {};
  if (Cookies.get('refreshToken')) {
    headers.Authorization = `Bearer ${Cookies.get('accessToken')}`;
  }
  if (isFileUpload) {
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'multipart/form-data';
  }

  return API({
    method: method.toLowerCase(),
    url: `${apiUrl}${url}`,
    params: method.toLowerCase() === 'get' ? data : {},
    data: method.toLowerCase() === 'get' ? {} : data,
    headers,
    validateStatus: (status) => status < 400,
  })
    .then((response) => response.data)
    .catch((error) => {
      if (!error.response) {
        if (process.env.NODE_ENV !== 'development') {
          window.location.href = '/no-connection'
        }
        return Promise.reject(new ResponseError('Произошла ошибка при запросе'))
      } else if (
        _try < 5 && error.response 
        && error.response.status === 401
        && Cookies.get('refreshToken')
      ) {
        return updateAccessToken().then(() => request(method, url, data, _try + 1, isFileUpload));
      } else if (error?.response?.status === 500) {
        toast.error('Произошла неизвестная ошибка')
        return Promise.reject(new ResponseError('Произошла неизвестная ошибка'))
      } else if (error?.response?.data?.detail) {
        return Promise.reject(new ResponseError(error.response.data.detail));
      } else {
        return Promise.reject(error);
      }
    });
}

export const requestTable = async (resourcePath, filters, sorting = [], page = 1, pageSize = 40) => {
  const data = { page, page_size: pageSize };
  if (sorting.length > 0) {
    data.order_column = sorting[0].id;
    data.order_desc = sorting[0].desc;
  }
  if (filters?.length > 0) {
    for (let filter of filters) {
      if (filter.value) {
        data[filter.fieldName] = filter.value;
      } else if (filter.values) {
        data[filter.fieldName] = filter.values;
      }
    }
  }
  return request('get', `${resourcePath}/`, data);
}

export const requestStatistic = async (resourcePath, filters) => {
  const data = {};
  if (filters?.length > 0) {
    for (let filter of filters) {
      if (filter.value) {
        data[filter.fieldName] = filter.value;
      } else if (filter.values) {
        data[filter.fieldName] = filter.values;
      }
    }
  }
  return request('get', `${resourcePath}`, data);
}

export const getRefreshToken = () => Cookies.get('refreshToken');

export const requestEntity = async (resourcePath, entityId, data) => {
  return request('get', `${resourcePath}/${entityId}`, data);
}

export const requestOptions = async (resourcePath) => request('get', `${resourcePath}/`)

export const updateRecord = async (recordPath, data) => request('patch', recordPath, data);

export const createRecord = async (resourcePath, data) => request('post', `${resourcePath}/`, data);

export const callAction = async (resourcePath) => request('post', resourcePath);

export const deleteRecord = async (resourcePath) => request('delete', resourcePath);

export const uploadFile = async (resourcePath, data) => request('post', resourcePath, data, 0, true);
