import React from 'react';
import Skeleton from 'react-loading-skeleton';


const AppTitle = ({ loading, children }) => {
  return (
    <div>
      {loading ? (
        <Skeleton
          width="10em"
          height="1.5em"
        />
      ) : children}
    </div>
  )
}

export default AppTitle;