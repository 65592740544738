import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthLayout from './components/AuthLayout';
import Dashboard from './routes/dashboard';
import Login from './routes/login';
import Logout from './routes/logout';
import SystemLayout from './components/SystemLayout';
import MoneyOperations from './routes/operations';
import Supplies from './routes/supplies';
import Balance from './routes/balance';
import Users from './routes/users';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Groups from './routes/groups/index';
import Group from './routes/group/index';
import { Goods, GoodsCard } from './routes/goods';
import AuditRecords from './routes/audit';
import Shipments from './routes/shipments';
import Supply from './routes/supply';
import NoConnection from './routes/NoConnection';
import 'react-loading-skeleton/dist/skeleton.css';
import {SkeletonTheme} from "react-loading-skeleton";
import { Turnovers, GoodsVariantTurnoverCard, WarehouseTurnoverCard } from './routes/tunrovers';
import { useEffect, useState } from 'react';
import ThemeContext from './ThemeContext';
import ReactModal from 'react-modal';
import { Seller, Sellers } from './routes/sellers';

function App() {
  ReactModal.setAppElement(document.getElementById('root'));

  const [theme, setTheme] = useState(localStorage.getItem('theme') || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'));

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.documentElement.dataset.theme = theme;
  }, [theme]);

  const routes = [
    {
      path: '/',
      label: 'Дашборд',
      icon: '/icons/Category.svg',
      element: <Dashboard />
    },
    {
      path: '/balance',
      label: 'Баланс',
      icon: '/icons/Wallet.svg',
      element: <Balance />,
      needGroup: true,
    },
    {
      path: '/goods',
      label: 'Проекты',
      icon: '/icons/Ticket Star.svg',
      element: <Goods />,
      needGroup: true,
    },
    {
      path: '/operations',
      label: 'Денежные операции',
      icon: '/icons/Swap.svg',
      element: <MoneyOperations />,
      needGroup: true,
    },
    {
      path: '/supplies',
      label: 'Себестоимость',
      icon: '/icons/Download.svg',
      element: <Supplies />,
      needGroup: true,
    },
    {
      path: '/shipments',
      label: 'Поставки',
      icon: '/icons/Upload.svg',
      element: <Shipments />,
      needGroup: true,
    },
    {
      path: '/turnover',
      label: 'Оборачиваемость',
      icon: '/icons/Buy.svg',
      element: <Turnovers />,
      needGroup: true,
    },
    {
      path: '/users',
      label: 'Пользователи',
      icon: '/icons/Two Users.svg',
      element: <Users />,
      adminOnly: true,
    },
    {
      path: '/groups',
      label: 'Группы',
      icon: '/icons/People.svg',
      element: <Groups />,
      adminOnly: true,
    },
    {
      path: '/audit',
      label: 'Аудит пользователей',
      element: <AuditRecords />,
      icon: '/icons/Audit.svg',
    },
    {
      path: '/sellers',
      label: 'Кабинеты',
      element: <Sellers />,
      icon: '/icons/Two Users.svg'
    },
    {
      path: '/groups/:groupId',
      element: <Group />,
    },
    {
      path: '/goods/:goodsId',
      element: <GoodsCard />,
    },
    {
      path: '/supplies/:supplyId',
      element: <Supply />
    },
    {
      path: '/turnover/:goodsVariantId',
      element: <GoodsVariantTurnoverCard />
    },
    {
      path: '/warehouse-turnover/:warehouseId',
      element: <WarehouseTurnoverCard />
    },
    {
      path: '/sellers/:sellerId',
      element: <Seller />
    }
  ]
  return (
    <div className={`default ${theme}`}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
      <SkeletonTheme
        borderRadius="10px"
        baseColor="var(--skeleton-base-color)"
        highlightColor="var(--skeleton-highlight-color)"
      >
        <BrowserRouter>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route element={<SystemLayout routes={routes} />}>
                {routes.map(({path, element}) => <Route key={path} path={path} element={element} />)}
              </Route>
            </Route>
            <Route
              path="/login"
              element={<Login />}
            />
            <Route
              path="/logout"
              element={<Logout />}
            />
            <Route
              path="/no-connection"
              element={<NoConnection />}
            />
            <Route
              path='*'
              element={<Login />}
            />
          </Routes>
        </BrowserRouter>
      </SkeletonTheme>
      <ToastContainer
        position="top-right"
        className='toast'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
