import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { callAction, updateRecord } from '../../../transport/api';
import ComponentContainer from '../../../components/ComponentContainer';
import Input from '../../../components/input';
import Button from '../../../components/button';

import styles from './card.module.css';

const Balance = ({ close, balance, goodsId }) => {
  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      name: balance?.name,
      initial: balance?.initial,
      initial_date: balance?.initial_date?.slice(0, 16),
      is_main: balance?.is_main
    },
  });

  const onSubmit = async (data) => {
    return updateRecord(`/goods/${goodsId}/balances/${balance?.id}`, data)
      .then(() => {
        toast.success('Баланс успешно обновлён');
        close();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="balance-form">
        <Input
          label="Баланс"
          register={() => register('name', { disabled: true })}
        />
        <Input
          label="Первоначальный баланс"
          type="number"
          options={{ min: 0 }}
          register={() => register('initial', { required: true })}
        />
        <Input
          label="Дата первоначального баланса"
          type="datetime-local"
          register={() => register('initial_date')}
        />
      </form>
      <div className={styles.buttons}>
        {!getValues('is_main') ? (
          <Button
            onClick={() =>
              callAction(
                `/goods/${goodsId}/balances/${balance?.id}/make-for-payment`
              ).then(() => {
                toast.success('Баланс успешно назначен для выплат от платформ');
                close();
              })
            }
          >
            Сделать для зачислений от платформ
          </Button>
        ) : undefined}
        <Button form="balance-form" type="submit">
          Сохранить
        </Button>
      </div>
    </ComponentContainer>
  );
};

export default Balance;
