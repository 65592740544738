import React from 'react';

import styles from './ComponentContainer.module.css';
import Skeleton from "react-loading-skeleton";

const ComponentContainer = ({ title, className, children, loading, loadingHeight }) => {
  return loading ? (
    <Skeleton height={loadingHeight || "100%"} />
  ) : (
    <div className={`${styles.container} ${className}`}>
      {title ? <div className={styles.title}>{title}</div> : null }
      <div className={`${styles.content} ${title ? '' : styles['no-title']}`}>
        {children}
      </div>
    </div>
  );
};

export default ComponentContainer;
