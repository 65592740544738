import React from 'react';
import { formatInt } from '../../utils';

import styles from './Indicator.module.css';
import Skeleton from "react-loading-skeleton";

const Indicator = ({ label, value, diff, measure, icon, className, loading, needPrepare = true, isDev = false }) => {
  const preparedValue = needPrepare ? (value instanceof Array ? value : [value])
    .map((v) => formatInt(v || 0))
    .join(' / ') : value;
  return loading ? (
    <Skeleton height="72px"/>
  ) : (
    <div className={`${styles.container} ${isDev ? styles.dev : ''}`}>
      {icon ? (
        <div className={`${styles['icon-halo']} ${className}`}>
          {icon}
        </div>
      ) : null}
      <div className={styles['text-container']}>
        <div className={styles['value-container']}>
          <div className={styles.value}>{preparedValue} {measure}</div>
          {!!diff
            ? <div className={diff < 0 ? styles['diff-negative'] : styles['diff-positive']}>
                {diff < 0 ? '▼' : '▲'} {formatInt(Math.abs(diff))} {measure}
              </div> : undefined}
        </div>
        <div>{label}</div>
      </div>
    </div>
  )
}

export default Indicator
