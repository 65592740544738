import React, { useState } from 'react';
import AppContainer from '../../components/app-container';
import Select from '../../components/Select';
import AppTable from './../../components/AppTable';
import User from '../user';
import EditModal from '../../components/EditModal';
import AddTitle from '../../components/AddTitle';
import { makeTitle } from '../../utils';


const Users = () => {
  makeTitle('Пользователи;')
  const [editingUserId, setEditingUserId] = useState();
  const [newUser, toggleNewUser] = useState(false);

  return (
    <AppContainer
      title={<AddTitle text='Пользователи' onAddClick={() => toggleNewUser(true)} />}
      filters={[
        <Select key={1} label='Группа' options={[{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }]} />, 
      ]}
    >
      <AppTable
        columns={[
          // { name: 'image_url', title: '' },
          { name: 'username', title: 'Имя пользователя' },
          { name: 'fullname', title: 'ФИО' },
          { name: 'email', title: 'Электронная почта' },
          { name: 'is_admin', title: 'Админ?', type: 'bool' },
          { name: 'group_name', title: 'Группа', canSort: false },
        ]}
        onClick={(row) => setEditingUserId(() => row.id)}
        resourcePath='/users'
        forceUpdate={`${editingUserId}-${newUser}`}
      />
      <EditModal
        isOpen={!!editingUserId || newUser}
        close={() => {setEditingUserId(undefined); toggleNewUser(false)}}
      >
        <User 
          userId={editingUserId}
          forCreate={newUser}
          close={() => {setEditingUserId(undefined); toggleNewUser(false)}}
        />
      </EditModal>
    </AppContainer>
  )
}

export default Users;
