import React, { forwardRef } from 'react';
import Button from '../button';

import styles from './EmojiButton.module.css';

const EmojiButton = forwardRef(({ onClick, className, emoji, disabled, containerClassName, title }, ref ) => {
  return (
    <div ref={ref} className={containerClassName}>
      <Button onClick={onClick} className={`${className} ${styles.button}`} disabled={disabled} title={title}>
        {emoji}
      </Button>
    </div>
  )
})

export default EmojiButton;
