export const formatInt = (value) => value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})

export const makeTitle = (title) => document.title = `MarketMirror | ${title}`

export const getDate = (type = 'datetime', addDays = 0) => {
  const now = new Date();
  const offset = now.getTimezoneOffset() * -1 / 60;
  now.setHours(now.getHours() + offset)
  if (addDays) {
    now.setDate(now.getDate() + addDays);
  }
  const date = now.toISOString();
  return date.slice(0, date.lastIndexOf(type === 'datetime' ? ':' : 'T'));
}

export const getISODateWOTZ = (date) => {
  if (!date) return null;
  let newDate = new Date(date);
  const offset = newDate.getTimezoneOffset() * -1 / 60;
  newDate.setHours(newDate.getHours() + offset)
  newDate = newDate.toISOString();
  return newDate.slice(0, newDate.lastIndexOf(':'));
}

export const dateToStr = (date) => {
  const result = date.toISOString();
  return result.slice(0, result.lastIndexOf('T'));
}
