import { useForm } from 'react-hook-form';
import { createRecord } from '../../../transport/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ComponentContainer from '../../../components/ComponentContainer';
import Input from '../../../components/input';
import Button from '../../../components/button';
import React from 'react';
import Alert from '../../../components/Alert';
import styles from './list.module.css'


const NewSeller = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    createRecord('/sellers', data)
      .then((responseData) => {
        toast.success('Кабинет успешно создан');
        navigate(`/sellers/${responseData.id}`);
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer title="Основная информация">
      <form onSubmit={handleSubmit(onSubmit)} id="saller-form">
        <Input
          label="Название"
          register={() =>
            register('name', {
              required: true,
            })
          }
        />
        <Alert>
          Api-ключ должен быть с полномочиями: Контент, Статистика, Аналитика и Продвижение
          <div className={styles.bold}>(всё в режиме только для чтения!)</div>
        </Alert>
        <Input
          label="Api-ключ"
          type="password"
          register={() => register('api_key', {
            required: true,
          })}
        />
        <Input
          label="Платформа"
          value="Wildberries"
          disabled
        />
      </form>
      <Button form="saller-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
}

export default NewSeller;
