import React, { useState } from 'react';
import AppContainer from '../../components/app-container';
import AppTable from './../../components/AppTable';
import { makeTitle } from '../../utils';
import EditModal from '../../components/EditModal';
import AuditInformation from './AuditInformation';


const AuditRecords = () => {
  makeTitle('Аудит пользователей')
  const [auditRecord, setAuditRecord] = useState(false);

  return (
    <AppContainer
      title='Аудит пользователей'
    >
      <AppTable
        columns={[
          { name: 'fullname', title: 'Имя пользователя' },
          { name: 'change_date', type: 'datetime', title: 'Дата изменений' },
          { name: 'uri', title: 'URI' },
          { name: 'methodname', title: 'Метод' },
        ]}
        resourcePath='/audit'
        defaultSorting={[{ id: 'change_date', desc: true }]}
        onClick={(row) => (
          setAuditRecord(() => (
            {
              id: row?.id,
              uri: row?.uri,
              fullname: row?.fullname,
              methodname: row?.methodname,
              data: row?.data,
              change_date: row?.change_date,
            }
          ))
        )}
      />
      <EditModal
        close={() => setAuditRecord(false)}
        showConfirmation={false}
        isOpen={!!auditRecord}
      >
        <AuditInformation
          close={() => setAuditRecord(false)}
          auditRecord={auditRecord}
        />
      </EditModal>
    </AppContainer>
  )
}

export default AuditRecords;
