import React from 'react';
import styles from './Alert.module.css'


const Alert = ({ children, style = 'info' }) => {
  return (
    <div className={`${styles.container} ${styles[style]}`}>
      {children}
    </div>
  )
}

export default Alert;
