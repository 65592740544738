import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { makeTitle } from '../../utils';

import AppContainer from './../../components/app-container/index';
import AppTable from './../../components/AppTable/index';
import EditModal from '../../components/EditModal';
import AddTitle from '../../components/AddTitle';
import Select from '../../components/Select';

import ShipmentCreateForm from './ShipmentCreateForm';

const Shipments = () => {
  makeTitle('Поставки');

  useEffect(() => {
    const message = sessionStorage.getItem('createShipment');
    if (message) {
      toast.success(message);
      sessionStorage.removeItem('createShipment');
    }
  });

  const [goodsVariantId, setGoodsVariantId] = useState();
  const [warehouseId, setWarehouseId] = useState();
  const [shipmentId, setShipmentId] = useState();
  const [newShipment, toggleNewShipment] = useState(false);

  return (
    <AppContainer
      title={<AddTitle text='Поставки' onAddClick={() => toggleNewShipment(true)} />}
      filters={[
        <Select
          value={warehouseId}
          onChange={(value) => setShipmentId(() => value)}
          resourcePath="/shipments/mp-ids"
          optionsColumns={{ value: 'wb_income_id', label: 'name' }}
          label="Поставка"
        />,
        <Select
          value={goodsVariantId}
          onChange={(value) => setGoodsVariantId(() => value)}
          resourcePath="/goods-variants"
          optionsColumns={{ value: 'id', label: 'vendor_code' }}
          label="Артикул"
        />,
        <Select
          value={warehouseId}
          onChange={(value) => setWarehouseId(() => value)}
          resourcePath="/warehouses"
          optionsColumns={{ value: 'id', label: 'name' }}
          label="Склад"
        />,
      ]}
    >
      <AppTable
        columns={[
          { name: 'wb_income_id', title: 'Идентификатор WB' },
          { name: 'vendor_code', title: 'Артикул', canSort: false },
          { name: 'warehouse_name', title: 'Склад', canSort: false },
          { name: 'volume', title: 'Количество', style: 'rectangle-blue' },
          { name: 'shipment_date', title: 'Дата поставки', type: 'date' },
          {
            name: 'is_shipment_in_supply',
            title: 'Все товары привязаны к партии?',
            type: 'forceBool',
          },
        ]}
        resourcePath="/shipments"
        filters={[
          { fieldName: 'goods_variant_id', value: goodsVariantId },
          { fieldName: 'wb_income_id', value: shipmentId },
          { fieldName: 'warehouse_id', value: warehouseId },
        ]}
        defaultSorting={[{ id: 'shipment_date', desc: true }]}
      />
      <EditModal
        isOpen={newShipment}
        close={() => toggleNewShipment(false)}
      >
        <ShipmentCreateForm />
      </EditModal>
    </AppContainer>
  );
};

export default Shipments;
