import React, { useContext, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { buildOptions } from './options';

import ComponentContainer from '../../ComponentContainer';
import ThemeContext from '../../../ThemeContext';

const BarShadowChart = ({
  title,
  labels,
  actualValues,
  oldValues,
  actualLabel,
  oldLabel,
  actualColor = '#FF8F6B',
  barBorder = 10,
  rightPadding = 20,
  showName = false,
  loading,
}) => {
  const [secondaryColor, setSecondaryColor] = useState()

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    setTimeout(() => setSecondaryColor(
      getComputedStyle(document.body)?.getPropertyValue('--bar-chart-secondary')), 0
    );
  }, [theme]);

  return (
    <ComponentContainer title={title} loadingHeight="28em">
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        style={{ height: '23em' }}
        option={buildOptions(
          labels,
          actualValues,
          oldValues,
          actualColor,
          actualLabel,
          oldLabel,
          barBorder,
          rightPadding,
          showName,
          secondaryColor
        )}
      />
    </ComponentContainer>
  );
};

export default BarShadowChart;
