import React, { useState, useEffect, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { requestTable } from './../../transport/api';
import CreatableSelect from 'react-select/creatable';

import styles from './UncontrolledSelect.module.css';
import { createFilter } from 'react-select';

const UncontrolledSelect = ({
  control,
  defaultValue,
  name,
  customOptions,
  label,
  resourcePath,
  optionsColumns,
  disabled,
  creatable = false,
  filters,
  additionalOnChange = (option) => undefined,
  loadOnFocus = false,
  isMulti = false,
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, toggleLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  
  const loadOptions = useCallback(() => {
    toggleLoading(true);
    if (customOptions) {
      setOptions(() => customOptions);
    }
    if (resourcePath && optionsColumns) {
      requestTable(resourcePath, filters, [], 1, 300).then((data) => {
        setOptions(() =>
          data?.list.map((item) => ({
            ...item,
            value: item[optionsColumns.value],
            label: item[optionsColumns.label],
          }))
        );
      });
    }
    toggleLoading(false);
  }, [customOptions, resourcePath, filters, optionsColumns]);

  useEffect(() => {
    if (!loadOnFocus && options.length === 0) {
      loadOptions()
    }
  }, [loadOnFocus, loadOptions, options]);


  return (
    <div className={styles.container}>
      {label ? <div className={styles.label}>{label}</div> : undefined}
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field: { onChange, onBlur, value, name, ref, values }}) => (
          <CreatableSelect
            filterOption={createFilter({
              matchFrom: 'any',
              stringify: option => `${option.label}`,
            })}
            isDisabled={disabled}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            isValidNewOption={() => creatable}
            formatCreateLabel={userInput => `Создать "${userInput}"`}
            inputValue={inputValue}
            onInputChange={(query, {action}) => {
              if (action !== 'set-value') {
                setInputValue(query)
              }
            }}
            styles={{
              control: (base, { isDisabled }) => ({
                ...base,
                minWidth: '10em',
                border: 'none',
                borderRadius: '10px',
                padding: '.25em',
                boxShadow: 'none',
                minHeight: '3em',
                background: isDisabled ? 'var(--control-disabled)' : 'var(--control-background)',
                color: 'var(--text-color)',
              }),
              menu: (base) => ({
                ...base,
                width: 'max-content',
                minWidth: '100%',
                maxHeight: '20em',
                overflowY: 'scroll',
                position: "absolute",
                right: 0,
                background: 'var(--control-background)',
              }),
              indicatorSeparator: (base) => ({ display: 'none' }),
              clearIndicator: (base) => ({ ...base, padding: '8px 8px 8px 0' }),
              dropdownIndicator: (base, state) => ({
                ...base,
                display: state.hasValue ? 'none' : undefined,
                maxHeight: '36px',
              }),
              singleValue: (base) => ({
                ...base,
                color: disabled ? 'var(--disabled-text-color)' : 'var(--text-color)',
                padding: '0'
              }),
              input: (base) => ({
                ...base,
                color: disabled ? 'var(--disabled-text-color)' : 'var(--text-color)',
              }),
              valueContainer: (base) => ({ ...base, padding: '0 .45em' }),
              menuList: (base) => ({
                ...base,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                background: 'var(--modal-background)',
              }),
              multiValue: (base) => ({
                ...base,
                background: 'var(--emoji-button-bg)',
                borderRadius: '10px',
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: 'var(--text-color)',
              }),
              option: (base) => ({
                ...base,
                ':active': {
                  background: 'var(--primary)'
                }
              })
            }}
            captureMenuScroll={false}
            placeholder="Выберите значение"
            options={options}
            isClearable
            isLoading={!!disabled && isLoading}
            inputRef={ref}
            value={Array.isArray(value) ? options.filter((option) => value.includes(option.value)) : options.find((option) => option.value === value)}
            name={name}
            onBlur={onBlur}
            onChange={(option) => {
              console.log(option, value, values);
              if (isMulti) {
                additionalOnChange(option.map(o => o?.value));
                onChange(option.map(o => o?.value));
              } else {
                additionalOnChange(option);
                onChange(option?.value);
              }
            }}
            onFocus={() => loadOnFocus ? loadOptions() : undefined}
            noOptionsMessage={() => 'Значение не найдено'}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'var(--control-background)',
                primary: 'var(--primary)',
                neutral50: 'var(--control-background)',
                primary10: 'var(--background)',
              },
            })}
          />
        )}
      ></Controller>
    </div>
  );
};

export default UncontrolledSelect;
