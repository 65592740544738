import React from 'react';
import ReactModal from 'react-modal';
import Button from '../button';

import styles from './ConfirmationModal.module.css';


const ConfirmationModal = ({ isOpen, title = 'Подтверждение', onConfirm, onCancel, confirmTitle = 'Ок', cancelTitle = 'Отмена' }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={styles.container}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles['button-container']}>
        <Button key='confirm' onClick={onConfirm}>{confirmTitle}</Button>
        <Button key='cancel' theme='secondary' onClick={onCancel}>{cancelTitle}</Button>
      </div>
    </ReactModal>
  )
}

export default ConfirmationModal;
