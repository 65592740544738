import { useForm } from 'react-hook-form';
import { requestEntity, updateRecord } from '../../../transport/api';
import { makeTitle } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ComponentContainer from '../../../components/ComponentContainer';
import Input from '../../../components/input';
import Button from '../../../components/button';
import React from 'react';
import AppContainer from '../../../components/app-container';
import Alert from '../../../components/Alert';

import styles from './card.module.css';
import RecordTable from '../../../components/RecordTable';


const Seller = () => {
  const { sellerId } = useParams();
  const navigate = useNavigate();

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: async () => requestEntity('/sellers', sellerId)
      .then((data) => (
        { ...data, id: data.id || sellerId }
      ))
      .catch((_) => navigate(-1, { replace: true })),
  });

  makeTitle(getValues('name'));

  const onSubmit = async (data) => {
    updateRecord(`/sellers/${sellerId}`, data)
      .then(() => toast.success('Данные успешно обновлены'))
      .catch((error) => toast.error(error.message));
  };

  return (
    <AppContainer title={getValues('name')}>
      <div className={styles.container}>
        <ComponentContainer title="Основная информация">
          <form onSubmit={handleSubmit(onSubmit)} id="seller-form">
            <Input
              label="Название"
              register={() =>
                register('name', {})
              }
            />
            <Alert>
              Api-ключ должен быть с полномочиями: Контент, Статистика, Аналитика и Продвижение
              <div className={styles.bold}>(всё в режиме только для чтения!)</div>
            </Alert>
            <Input
              label="Api-ключ"
              type="password"
              register={() => register('api_key', {})}
            />
            <Input
              label="Платформа"
              value="Wildberries"
              disabled
            />
          </form>
          <Button form="seller-form" type="submit">Сохранить</Button>
        </ComponentContainer>
        <RecordTable
          title="Интеграция"
          containerClassName={styles['table-container']}
          columns={[
            { name: 'name', title: 'Название' },
            { name: 'last_date', title: 'Дата последней успешной интеграции', type: 'datetime' },
          ]}
          resourcePath={`/sellers/${sellerId}/api-resources`}
        />
      </div>
    </AppContainer>
  );
}

export default Seller;