import React from 'react';
import Button from '../button';

import styles from './AddTitle.module.css';


const AddTitle = ({ text, onAddClick, disabled }) => {
  return (
    <div className={styles.container}>
      {text}
      <Button className={styles.button} onClick={onAddClick} disabled={disabled}>+</Button>
    </div>
  )
}

export default AddTitle;
