import React from 'react';
import { makeTitle } from '../../utils';
import Button from '../../components/button';
import { useNavigate } from 'react-router-dom';

import styles from './NoConnection.module.css';

const NoConnection = () => {
  makeTitle('Нет соединения');

  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.title}>🫠</div>
        <div>Похоже мы потеряли связь с сервером...</div>
        <Button onClick={() => navigate(-1, { replace: true })}>Проверить досупность</Button>
      </div>
    </div>
  )
}

export default NoConnection;
