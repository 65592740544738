import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  requestEntity,
  requestTable,
} from "../../../transport/api";

import AppTitle from "../../../components/AppTitle";
import AppContainer from "../../../components/app-container";
import { HorizontalBar } from "../../../components/charts";
import Input from "../../../components/input";

import styles from "./warehouse-turnover-card.css";

import { getDate, makeTitle } from "../../../utils";
import Skeleton from "react-loading-skeleton";

const WarehouseTurnoverCard = () => {
  const navigate = useNavigate();

  const chartRef = useRef();
  const { warehouseId } = useParams();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [periodStart, setPeriodStart] = useState(getDate("date", -14));
  const [periodEnd, setPeriodEnd] = useState(getDate("date"));

  const [submitPeriodStart, setSubmitPeriodStart] = useState(periodStart);
  const [submitPeriodEnd, setSubmitPeriodEnd] = useState(periodEnd);

  const [horizontalBarData, setHorizontalBarData] = useState({});

  useEffect(() => {
    requestEntity('/warehouses', warehouseId).then((data) => {
      setData(() => data);
      setLoading(false);
    });

    requestTable(`/turnover/warehouse-turnover/${warehouseId}/goods_variants`,
      [
        { fieldName: "period_start", value: submitPeriodStart },
        { fieldName: "period_end", value: submitPeriodEnd }
      ],
      [{ id: "turnover", desc: false }]
    ).then((data) => setHorizontalBarData(data.list));
  }, [warehouseId, submitPeriodStart, submitPeriodEnd]);

  useEffect(() => {
    let instance = chartRef?.current?.getEchartsInstance();
    if (instance) {
      instance.on('dblclick', (params) => {
        navigate(`/turnover/${params.data[5]}`);
      });
    }
  }, []);

  makeTitle(`Оборачиваемость по складу ${data?.name}`);

  return (
    <AppContainer
      title={<AppTitle loading={loading}>{data?.name}</AppTitle>}
      // {`Оборачиваемость по складу ${data.name}`}
      filters={[
        <div>Среднее за период:</div>,
        <Input
          type="date"
          value={periodStart}
          onChange={(e) => setPeriodStart(() => e.target.value)}
          options={{
            onBlur: () => setSubmitPeriodStart(periodStart),
          }}
          isFilter
        />,
        <div>—</div>,
        <Input
          type="date"
          value={periodEnd}
          onChange={(e) => setPeriodEnd(() => e.target.value)}
          options={{
            onBlur: () => setSubmitPeriodEnd(periodEnd),
          }}
          isFilter
        />,
      ]}
    >
      <div className={styles["container"]}>
        <div>
          {horizontalBarData ? (
            <HorizontalBar
              title={"Оборачиваемость по складам"}
              data={horizontalBarData}
              height={30 * horizontalBarData.length}
              tooltip_options={[
                {'marketplace_units_left': 'Осталось шт.'}, 
                {'avg_period_orders': 'Средние продажи'},
                {'id': 'Идентификатор'}
              ]}
              chartRef={chartRef}
              yAxisLabelShow={true}
            />
          ) : (
            <Skeleton height="28.5em" />
          )}
        </div>
      </div>
    </AppContainer>
  );
};

export default WarehouseTurnoverCard;
