import React, { useContext, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { buildOptions } from './options';

import ComponentContainer from '../../ComponentContainer';
import ThemeContext from '../../../ThemeContext';

const HorizontalBar = ({
  title,
  data,
  height,
  tooltip_options,
  chartRef,
  yAxisLabelShow
}) => {
  const [textColor, setTextColor] = useState()

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    setTimeout(() => setTextColor(
      getComputedStyle(document.body)?.getPropertyValue('--text-color')), 0
    );
  }, [theme]);

  return (
    <ComponentContainer>
      <ReactECharts
        title={title}
        notMerge={true}
        lazyUpdate={true}
        style={{ height: height + 72}} 
        option={buildOptions(
          data, 
          height, 
          tooltip_options, 
          yAxisLabelShow,
          textColor
        )}
        ref={chartRef}
      />
    </ComponentContainer>
  );
};

export default HorizontalBar;
