export const isFiltersEqual = (filters1, filters2) => {
  if ((
    !filters1 && filters2
  ) || (
    filters1 && !filters2
  )) {
    return false;
  }
  if (!filters1 && !filters2) {
    return true;
  }
  if (filters1.length !== filters2.length) {
    return false;
  }
  for (let filter of filters1) {
    const equalFilter = filters2.find(
      (filter2) =>
        filter2.fieldName === filter.fieldName && filter2.value === filter.value,
    );
    if (!equalFilter) {
      return false;
    }
  }
  return true;
};

export const isSortingEqual = (sorting1, sorting2) => {
  if (sorting1.length === 0 && sorting2.length === 0) {
    return true;
  }
  return !(
    sorting1.length !== sorting2.length ||
    sorting1[0].id !== sorting2[0].id ||
    sorting1[0].desc !== sorting2[0].desc
  );

};