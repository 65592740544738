import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { updateRecord } from '../../transport/api';
import { getISODateWOTZ } from '../../utils';

import ComponentContainer from '../../components/ComponentContainer';
import Input from '../../components/input';
import Button from '../../components/button';

const SupplyEdit = ({ name, supplyDate, supplyId, hiddenVolume, volume }) => {
  const [newHiddenVolume, setNewHiddenVolume] = useState(hiddenVolume || 0);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: name,
      supply_date: getISODateWOTZ(supplyDate),
    },
  });

  const onSubmit = async (data) => {
    return updateRecord(`/supplies/${supplyId}`, data)
      .then(() => {
        sessionStorage.setItem('updateSupply', 'Партия успешно обновлена');
        window.location.reload(false);
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="percent-form">
        <Input
          label={"Название"}
          type="text"
          register={() => register('name')}
        />
        <Input
          label={"Дата партии"}
          type="datetime-local"
          register={() => register('supply_date', { required: true })}
        />
        <Input
          label="Озон"
          type="number"
          value={newHiddenVolume}
          options={{ min: 0, max: volume }}
          register={() => register('hidden_volume', {
            onChange: (e) => setNewHiddenVolume(e.target.value)
          })}
        />
      </form>
      <Button form="percent-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default SupplyEdit;
