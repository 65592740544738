import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateRecord } from '../../transport/api';
import ComponentContainer from '../../components/ComponentContainer';
import Input from '../../components/input';
import Button from '../../components/button';

const MasterBalance = ({ close, balance }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: balance?.name,
      initial: balance?.initial,
      initial_date: balance?.initial_date?.slice(0, 16),
    },
  });

  const onSubmit = async (data) => {
    return updateRecord(`/balances/${balance?.id}`, data)
      .then(() => {
        toast.success('Баланс успешно обновлён');
        close();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="balance-form">
        <Input
          label="Баланс"
          register={() => register('name', { disabled: true })}
        />
        <Input
          label="Первоначальный баланс"
          type="number"
          options={{ min: 0 }}
          register={() => register('initial')}
        />
        <Input
          label="Дата первоначального баланса"
          type="datetime-local"
          register={() => register('initial_date')}
        />
      </form>
      <Button form="balance-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default MasterBalance;
