import React from 'react';
import Indicator from './../../components/indicator/index';

import styles from './Supply.module.css';

const Indicators = ({
  volume,
  remainingVolume,
  hiddenVolume,
  costPrice,
  vendorCode,
  date,
}) => {
  return (
    <div className={styles.indicators}>
      <Indicator label="Объём" value={volume} measure="шт." />
      <Indicator
        label="Оставшийся объём"
        value={remainingVolume}
        measure="шт."
      />
      <Indicator label="Озон" value={hiddenVolume} measure="шт." />
      <Indicator label="Себестоимость" value={costPrice} measure="₽" />
      <Indicator label="Артикул" value={vendorCode} />
      <Indicator label="Дата" value={date} />
    </div>
  );
};

export default Indicators;
