import React from 'react';
import AppContainer from '../../components/app-container';
import AppTable from './../../components/AppTable';
import AddTitle from '../../components/AddTitle';
import { useNavigate } from 'react-router-dom';
import { makeTitle } from '../../utils';


const Groups = () => {
  makeTitle('Группы')
  const navigate = useNavigate();

  return (
    <AppContainer
      title={<AddTitle text='Группы' onAddClick={() => navigate('/groups/new')} />}
    >
      <AppTable
        columns={[
          { name: 'name', title: 'Имя' },
          { name: 'users_count', title: 'Количество пользователей', canSort: false },
          { name: 'sellers_count', title: 'Количество селлеров', canSort: false },
        ]}
        onClick={(row) => navigate(`/groups/${row.id}`)}
        resourcePath='/groups'
      />
    </AppContainer>
  )
}

export default Groups;
