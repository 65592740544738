import React from 'react';
import { useForm } from 'react-hook-form';
import { createRecord } from '../../transport/api';
import Input from '../../components/input';

import ComponentContainer from '../../components/ComponentContainer/index';
import Button from '../../components/button/index';
import { toast } from 'react-toastify';

const Dividend = ({ investorId, close }) => {
  const { register, handleSubmit } = useForm({ defaultValues: {} });

  const onSubmit = async (data) => {
    return createRecord(`/investors/${investorId}/dividends/new`, data)
      .then(() => {
        toast.success('Дивиденд успешно добавлен');
        close();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="dividend-form">
        <Input
          label="Сумма"
          type="number"
          options={{ min: 0 }}
          register={() => register('value', { required: true })}
        />
        <Input
          label="Начало периода"
          type="date"
          register={() => register('period_start', { required: true })}
        />
        <Input
          label="Конец периода"
          type="date"
          register={() => register('period_end', { required: true })}
        />
      </form>
      <Button form="dividend-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default Dividend;
