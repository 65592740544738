import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { requestEntity, requestStatistic } from '../../../transport/api';

import AppContainer from '../../../components/app-container';
import AppTitle from '../../../components/AppTitle';
import Button from '../../../components/button';
import { BarShadowChart, LineChart } from '../../../components/charts';
import ComponentContainer from '../../../components/ComponentContainer';
import EditModal from '../../../components/EditModal';
import EmojiButton from '../../../components/EmojiButton';
import Indicator from '../../../components/indicator';
import Input from '../../../components/input';
import Operation from '../../operations/Operation';
import PositionalModal from '../../../components/PositionalModal';
import RecordTable from '../../../components/RecordTable';
import Select from '../../../components/Select';
import Switch from '../../../components/Switch';

import GoodsEdit from './GoodsEdit';
import Balance from './Balance';

import { makeTitle } from '../../../utils';

import styles from './card.module.css';
import LinkVariantsForm from './LinkVariantsForm';

const GoodsCard = () => {
  const { goodsId } = useParams();
  const [data, setData] = useState({});
  const [period, setPeriod] = useState('all_time');
  const [chartType, setChartType] = useState('profit');
  const [dynamicData, setDynamicData] = useState();
  const [storageData, setStorageData] = useState([]);
  const [isGoodsEdit, toggleGoodsEdit] = useState(false);
  const [isSharedEdit, toggleSharedEdit] = useState(false);
  const [balance, setBalance] = useState();
  const [newOperation, setNewOperation] = useState('');
  const [goodsVariantId, setGoodsVariantId] = useState();
  const [isOperationModalOpen, toggleOperationModalOpen] = useState(false);
  const [isLinkVariantsOpen, toggleLinkVariantsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [operation, setOperation] = useState();

  const [adName, setAdName] = useState();
  const [adBillDate, setAdBillDate] = useState('');
  const [submitAdBillDate, setSubmitAdBillDate] = useState(adBillDate);

  const operationButton = useRef()

  makeTitle(`Проект ${data?.name}`);

  useEffect(() => {
    requestEntity('/goods', goodsId, {
      period,
      goods_variant_id: goodsVariantId,
    }).then((data) => {
      setData(() => data);
      setLoading(false);
    });
    requestStatistic(`/goods/${goodsId}/dynamic`, [
      { fieldName: 'period', value: period },
      { fieldName: 'goods_variant_id', value: goodsVariantId },
    ]).then((data) => setDynamicData(data));
    requestStatistic(`/goods/${goodsId}/storages`, [
      { fieldName: 'goods_variant_id', value: goodsVariantId },
    ]).then((data) => setStorageData(data));
  }, [goodsId, period, isGoodsEdit, newOperation, balance, goodsVariantId, isLinkVariantsOpen]);

  return (
    <AppContainer
      title={
        <div className={styles['button-container']}>
          <AppTitle loading={loading}>Проект {data?.name}</AppTitle>
          <EmojiButton
            onClick={() => toggleGoodsEdit(true)}
            className={styles.button}
            emoji="✏️"
            disabled={loading}
          />
          <EmojiButton
            disabled={loading}
            onClick={() => toggleOperationModalOpen(true)}
            emoji="💸"
            className={styles.button}
            ref={operationButton}
          />
          <EmojiButton
            disabled={loading}
            onClick={() => toggleLinkVariantsOpen(true)}
            emoji="🔗"
            title="Привязать артикулы"
            className={styles.button}
            ref={operationButton}
          />
        </div>
      }
      filters={[
        <Select
          value={goodsVariantId}
          onChange={(value) => setGoodsVariantId(() => value)}
          resourcePath={`/goods/${goodsId}/variants`}
          optionsColumns={{ value: 'id', label: 'vendor_code' }}
          label="Артикул"
          disabled={loading}
        />,
        <Switch
          value={period}
          onChange={(e) => {
            setLoading(true);
            setPeriod(e.target.value);
          }}
          isFilter
          options={[
            { value: 'week', label: 'Неделя' },
            { value: 'month', label: 'Месяц' },
            { value: 'all_time', label: 'Всё время' },
          ]}
        />,
      ]}
    >
      <div className={styles['indicator-container']}>
        <Indicator
          key="balance"
          label="Баланс"
          value={data?.balance}
          measure="₽"
          diff={data?.balance_diff}
          loading={loading}
        />
        <Indicator
          key="group_profit"
          label="Доступно для вывода / инвесторам"
          value={[data?.group_profit, data?.investors_profit]}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="investment"
          label="Вложения / группа"
          value={[data?.investment, data?.group_investment]}
          measure="₽"
          diff={data?.investment_diff}
          loading={loading}
        />
        <Indicator
          key="tax"
          label={`Налоги за ${data?.quarter_name}`}
          value={data?.quarter_tax}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="profit"
          label="Прибыль"
          value={Math.round(data?.profit)}
          measure="₽"
          diff={Math.round(data?.profit_diff)}
          loading={loading}
        />
        <Indicator
          key="revenue"
          label="Выручка"
          value={Math.round(data?.revenue)}
          measure="₽"
          diff={Math.round(data?.revenue_diff)}
          loading={loading}
        />
        <Indicator
          key="sales"
          label="Продажи"
          value={data?.sales}
          measure="шт."
          diff={data?.sales_diff}
          loading={loading}
        />
        <Indicator
          key="returns"
          label="Возвраты"
          value={data?.returns}
          measure="шт."
          diff={data?.returns_diff}
          loading={loading}
        />
        <Indicator
          key="total_supply_balance"
          label="Товарный баланс"
          value={data?.total_supply_balance}
          measure="₽"
          loading={loading}
        />
      </div>
      <div className={styles['image-table-container']}>
        <ComponentContainer loading={loading}>
          <img
            src={data?.image_url || '/icons/Image 2.svg'}
            alt="Изображение проекта"
            className={`${styles.image} ${
              !data?.image_url ? styles['no-image'] : ''
            }`}
          />
        </ComponentContainer>
        <BarShadowChart
          // loading={dynamicData === undefined}
          title={
            <div className={styles['chart-filter']}>
              Динамика
              <Switch
                containerClassName={styles['chart-switch']}
                options={[
                  { value: 'profit', label: 'Прибыль' },
                  { value: 'sales', label: 'Количество' },
                  { value: 'revenue', label: 'Выручка' },
                ]}
                value={chartType}
                onChange={(e) => setChartType(e.target.value)}
              />
            </div>
          }
          labels={dynamicData?.map((item) => item.label)}
          actualValues={dynamicData?.map((item) =>
            Math.round(item.actual[chartType])
          )}
          oldValues={dynamicData?.map((item) =>
            Math.round(item.previous[chartType])
          )}
          actualLabel="Текущие"
          oldLabel="Предыдущие"
          barBorder={3}
          rightPadding={9}
          showName
        />
      </div>
      <div className={styles['chart-container']}>
        <RecordTable
          title="История последних транзакций"
          columns={[
            { name: 'operation_date', title: 'Дата', type: 'date', customStyle: styles['fixed-column'] },
            {
              name: 'volume',
              title: 'Сумма',
              type: 'number',
              customStyle: styles['fixed-column'],
            },
            {
              name: 'operation_type_desc',
              title: 'Тип',
              customStyle: styles['fixed-column'],
            },
            { name: 'balance_from_name', title: 'Баланс списания', customStyle: styles['balance-column'] },
            { name: 'balance_to_name', title: 'Баланс зачисления', customStyle: styles['balance-column'] },
            { name: 'description', title: 'Комментарий' },
          ]}
          resourcePath={`/goods/${goodsId}/operations`}
          defaultSorting={[{ id: 'operation_date', desc: true }]}
          forceUpdate={`${newOperation} ${!!operation?.newOperation}`}
          onClick={(row) => {
            setOperation({
              newOperation: row.operation_type,
              editingOperationId: row.main_operation_uid,
              investorId: row.balance_from_id,
              defaultVolume: row.volume,
              defaultFromBalanceName: row.balance_from_name,
              defaultToBalanceName: row.balance_to_name
            })
          }}
          getDeleteProperties={(row) => ({ path: `/money-operations/${row.main_operation_uid}`})}
        />
        <EditModal close={() => setOperation(undefined)} isOpen={!!operation?.newOperation}>
          <Operation
            close={() => setOperation()}
            newOperation={operation?.newOperation}
            editingOperationId={operation?.editingOperationId}
            investorId={operation?.investorId}
            defaultVolume={operation?.defaultVolume}
            defaultFromBalanceName={operation?.defaultFromBalanceName}
            defaultToBalanceName={operation?.defaultToBalanceName}
            onlyShared={operation?.onlyShared}
          />
        </EditModal>
      </div>
      <div className={styles['row-table-container']}>
        <RecordTable
          title="Балансы"
          columns={[
            { name: 'physical_balance_name', title: 'Физический баланс' },
            { name: 'initial', title: 'Первоначальный баланс', type: 'number' },
            {
              name: 'initial_date',
              title: 'Дата первоначального баланса',
              type: 'date',
            },
            { name: 'current_value', title: 'Текущий остаток', type: 'number' },
            { name: 'is_main', title: 'Основной баланс', type: 'bool' },
          ]}
          resourcePath={`/goods/${goodsId}/balances`}
          onClick={(row) =>
            setBalance({
              id: row.id,
              initial: row.initial,
              name: row.name,
              initial_date: row.initial_date,
              is_main: row.is_main,
            })
          }
          forceUpdate={!!balance?.id}
          defaultSorting={[{ id: 'current_value', desc: true }]}
        />
        <EditModal close={() => setBalance(undefined)} isOpen={!!balance?.id}>
          <Balance
            close={() => setBalance()}
            goodsId={goodsId}
            balance={balance}
          />
        </EditModal>
      </div>
      <div className={styles['local-table']}>
        <RecordTable
          title={(
            <div className={styles['title-container']}>
              <div>Рекламные кампании</div>
              <div className={styles['title-filters']}>
                <Input
                  type="date"
                  value={adBillDate}
                  onChange={(e) => setAdBillDate(() => e.target.value)}
                  options={{
                    onBlur: () => setSubmitAdBillDate(adBillDate),
                  }}
                  isFilter
                />
                <Select
                  value={adName}
                  onChange={(value) => setAdName(value)}
                  resourcePath={`/goods/${goodsId}/advertisments/names`}
                  optionsColumns={{ value: 'name', label: 'name' }}
                  label="Имя кампании"
                  disabled={loading}
                />
              </div>
            </div>
          )}
          columns={[
            { name: 'name', title: 'Название' },
            { name: 'mp_bill_id', title: 'Номер договора' },
            { name: 'status_desc', title: 'Статус' },
            { name: 'type_desc', title: 'Тип' },
            { name: 'volume', title: 'Затраты', type: 'number' },
            { name: 'bill_date', title: 'Дата', type: 'date' },
            { name: 'is_paid', title: 'Оплачена?', type: 'forceBool' },
          ]}
          filters={[
            { fieldName: 'bill_date', value: submitAdBillDate },
            { fieldName: 'name', value: adName },
          ]}
          resourcePath={`/goods/${goodsId}/advertisments`}
          defaultSorting={[{ id: 'bill_date', desc: true }]}
        />
      </div>
      <div className={styles['chart-container']}>
        <LineChart
          title="Хранение"
          labels={storageData.map((storage) => storage.storage_date)}
          data={storageData.map((storage) => storage.volume)}
          formatter={(params) => {
            const storage = storageData.find((storage) => storage.storage_date === params?.[0].name);
            return `${params?.[0].name}<br/>${storage.has_operation ? '✔️' : '❌'} <bold>${storage.volume}</bold>`;
          }}
        />
      </div>
      <EditModal close={() => {
        toggleGoodsEdit(false);
        toggleSharedEdit(false);
      }} isOpen={isGoodsEdit}>
        <GoodsEdit
          close={() => {
            toggleGoodsEdit(false);
            toggleSharedEdit(false);
          }}
          isOpen={isGoodsEdit}
          goodsId={goodsId}
          percent={data?.percent}
          initial_date={data?.initial_date}
          isSharedEdit={isSharedEdit}
        />
      </EditModal>
      <EditModal
        close={() => setNewOperation(undefined)}
        isOpen={!!newOperation}
      >
        <Operation
          close={() => setNewOperation(undefined)}
          newOperation={newOperation}
          defaultFromBalanceName={{
            PAYMENT: data?.goods_balance_name,
            TAX: data?.goods_balance_name,
            OUTPUT: data?.goods_balance_name,
            INNER_TRANSFER: data?.goods_balance_name,
            INPUT: null,
            DEPOSIT: data?.master_balance_name,
            WITHDRAW: data?.goods_balance_name,
          }[newOperation]}
          defaultToBalanceName={{
            PAYMENT: null,
            TAX: null,
            OUTPUT: null,
            INNER_TRANSFER: data?.master_balance_name,
            INPUT: data?.goods_balance_name,
            DEPOSIT: data?.goods_balance_name,
            WITHDRAW: data?.master_balance_name,
          }[newOperation]}
          defaultVolume={newOperation === 'TAX' ? Math.round(data?.quarter_tax) : 0}
        />
      </EditModal>
      <PositionalModal
        positionFrom={{ left: operationButton.current?.offsetLeft, top: operationButton.current?.offsetTop }}
        isOpen={isOperationModalOpen}
        height="24em"
        width="12.5em"
        close={() => toggleOperationModalOpen(false)}
      >
        {[
          ['PAYMENT', 'Оплата партии'],
          ['OUTPUT', 'Новый расход'],
          ['TAX', 'Оплата налога'],
          ['INNER_TRANSFER', 'Вывод прибыли'],
          ['INPUT', 'Внесение баланса'],
          ['DEPOSIT', 'Ввод вложений'],
          ['WITHDRAW', 'Вывод вложений'],
          ['IS_USED_IN_SHARED', 'Участие в общих операциях'],
        ].map(([operation, name]) => (
          <Button
            className={styles['modal-button']}
            theme="borderless"
            key={operation}
            onClick={() => {
              if (operation === 'IS_USED_IN_SHARED') {
                toggleGoodsEdit(true);
                toggleSharedEdit(true);
              } else {
                setNewOperation(operation);
              }
              toggleOperationModalOpen(false);
            }}
          >
            {name}
          </Button>
        ))}
      </PositionalModal>
      <EditModal isOpen={isLinkVariantsOpen} close={() => toggleLinkVariantsOpen(false)}>
        <LinkVariantsForm close={() => toggleLinkVariantsOpen(false)} goodsId={goodsId} />
      </EditModal>
    </AppContainer>
  );
};

export default GoodsCard;
