import React, { useContext, useState } from 'react';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import NavbarLink from './NavbarLink';

import styles from './SystemLayout.module.css';
import EditModal from '../EditModal';
import User from '../../routes/user';
import EmojiButton from '../EmojiButton';
import ThemeContext from '../../ThemeContext';

const SystemLayout = ({ routes }) => {
  const location = useLocation();
  const { theme, setTheme } = useContext(ThemeContext);

  const [isEditUser, toggleEditUser] = useState(false);

  return (
    <div className={styles['main-container']}>
      <div className={styles['navbar-container']}>
        <div className={styles['logo-container']}>
          <img src="/logo.png" alt="logo" className={styles['logo-image']} />
          {/*<div className={styles['logo-title']}></div>*/}
        </div>
        <div className={styles['routes-container']}>
          {routes
            .filter(
              ({ label, adminOnly, needGroup }) =>
                label &&
                (needGroup && Cookies.get('groupName') === 'null'
                  ? false
                  : adminOnly
                  ? Cookies.get('isAdmin') === 'true'
                  : true)
            )
            .map(({ path, label, icon }) => (
              <NavbarLink
                key={path}
                to={path}
                icon={icon}
                selected={
                  (path !== '/' && location.pathname.includes(path)) ||
                  (location.pathname === '/' && path === '/')
                }
              >
                {label}
              </NavbarLink>
            ))}
        </div>
        <div className={styles['theme-switcher']}>
          <EmojiButton emoji={theme === 'dark' ? '🌙' : '☀️'} onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} />
        </div>
        <div
          className={styles['bottom-container']}
          onClick={() => toggleEditUser(true)}
        >
          <div className={styles['profile-container']}>
            <img
              src={
                'https://ui-avatars.com/api/?name='
                + Cookies.get('fullName').split(' ').join('+')
              }
              alt="user profile"
              className={styles['user-avatar']}
            />
            <div className={styles['title-container']}>
              <div className={styles['user-title']}>
                {Cookies.get('fullName')}
              </div>
              <div className={styles['user-sub-title']}>
                {Cookies.get('isAdmin') === 'true'
                  ? 'Администратор'
                  : Cookies.get('groupName')}
              </div>
            </div>
          </div>
          <NavLink to="/logout">
            <img
              src="/icons/Logout.svg"
              alt="logout"
              className={styles['logout-icon']}
            />
          </NavLink>
        </div>
        <EditModal close={() => toggleEditUser(false)} isOpen={isEditUser}>
          <User
            userId={Cookies.get('userId')}
            forCreate={false}
            fromProfile
            close={() => toggleEditUser(false)}
          />
        </EditModal>
      </div>
      <Outlet />
    </div>
  );
};

export default SystemLayout;
