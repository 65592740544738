import React, { useEffect, useState } from 'react';
import Indicator from '../../components/indicator';
import { BarShadowChart } from '../../components/charts';
import AppContainer from './../../components/app-container/index';

import styles from './Dashboard.module.css';
import Select from '../../components/Select';
import RecordTable from '../../components/RecordTable';
import { requestStatistic } from '../../transport/api';
import Switch from '../../components/Switch';
import { makeTitle } from '../../utils';

const getActualLabel = (period) => {
  if (period === 'today') {
    return 'Сегодня';
  }
  if (period === 'yesterday') {
    return 'Вчера';
  }
  if (period === 'week') {
    return 'Эта неделя';
  }
};

const getOldLabel = (period) => {
  if (period === 'today') {
    return 'Вчера';
  }
  if (period === 'yesterday') {
    return 'Позавчера';
  }
  if (period === 'week') {
    return 'Прошлая неделя';
  }
};

const getRightPadding = (period) => {
  return period === 'week' ? 25 : 4;
};

const Dashboard = () => {
  makeTitle('Дашборд')

  const [goodsVariantId, setGoodsVariantId] = useState();
  const [sellerId, setSellerId] = useState();
  const [period, setPeriod] = useState('today');
  const [chartType, setChartType] = useState('orders');
  const [dataType, setDataType] = useState('amount');
  const [chartData, setChartData] = useState({});
  const [statistics, setStatistics] = useState({});
  const [unlinkedCount, setUnlinkedCount] = useState(0);

  useEffect(() => {
    requestStatistic('/statistic/current', [
      { fieldName: 'seller_id', value: sellerId },
      { fieldName: 'goods_variant_id', value: goodsVariantId },
      { fieldName: 'period', value: period },
    ]).then((data) => setStatistics(data));
    requestStatistic('/goods-variants/unlinked').then((data) => setUnlinkedCount(data.total))
  }, [goodsVariantId, sellerId, period]);

  useEffect(() => {
    if (chartType === 'orders') {
      setChartData(() => statistics?.order_dynamic);
    } else {
      setChartData(() => statistics?.sales_dynamic);
    }
  }, [chartType, statistics]);

  return (
    <AppContainer
      title="Дашборд"
      filters={[
        <Select
          value={sellerId}
          onChange={(value) => setSellerId(() => value)}
          resourcePath="/sellers"
          optionsColumns={{ value: 'id', label: 'name' }}
          label="Продавец"
        />,
        <Select
          value={goodsVariantId}
          onChange={(value) => setGoodsVariantId(() => value)}
          resourcePath="/goods-variants"
          optionsColumns={{ value: 'id', label: 'vendor_code' }}
          label="Артикул"
          filters={[{ fieldName: 'seller_id', value: sellerId }]}
        />,
        <Switch
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          isFilter
          options={[
            { value: 'yesterday', label: 'Вчера' },
            { value: 'today', label: 'Сегодня' },
            { value: 'week', label: 'За неделю' },
          ]}
        />,
      ]}
    >
      <div className={`${styles['indicator-container']} ${unlinkedCount > 0 ? styles['indicator-5'] : styles['indicator-4']}`}>
        <Indicator
          key="order-count"
          label="Заказали"
          value={statistics?.order_count?.target}
          measure="шт."
          diff={
            statistics?.order_count?.target - statistics?.order_count?.previous
          }
          icon={<img alt="buy" src="./icons/Buy.svg" />}
          className={styles['order-amount']}
        />
        <Indicator
          key="order-money"
          label="Заказали"
          value={Math.round(statistics?.order_amount?.target)}
          measure="₽"
          diff={Math.round(
            statistics?.order_amount?.target -
              statistics?.order_amount?.previous
          )}
          icon={<img alt="buy" src="./icons/Buy.svg" />}
          className={styles['order-money']}
        />
        <Indicator
          key="sell-amount"
          label="Выкупили"
          value={statistics?.sales_count?.target}
          measure="шт."
          diff={
            statistics?.sales_count?.target - statistics?.sales_count?.previous
          }
          icon={<img alt="wallet" src="./icons/Wallet.svg" />}
          className={styles['sell-amount']}
        />
        <Indicator
          key="sell-money"
          label="Выкупили"
          value={Math.round(statistics?.sales_amount?.target)}
          measure="₽"
          diff={Math.round(
            statistics?.sales_amount?.target -
              statistics?.sales_amount?.previous
          )}
          icon={<img alt="wallet" src="./icons/Wallet.svg" />}
          className={styles['sell-money']}
        />
        {unlinkedCount > 0 ? <Indicator
          key="unlinked-goods-variants"
          label="Артикулы без привязки"
          value={unlinkedCount}
          measure="шт"
          icon={<img alt="danger" src="./icons/Danger.svg" />}
          className={styles['danger']}
        /> : undefined}
      </div>
      <div className={styles['chart-container']}>
        {chartData ? (
          <BarShadowChart
            title={
              <div className={styles['chart-filter']}>
                Выкупы
                <Switch
                  containerClassName={styles['chart-switch']}
                  options={[
                    { value: 'orders', label: 'Заказали' },
                    { value: 'sales', label: 'Выкупили' },
                  ]}
                  value={chartType}
                  onChange={(e) => setChartType(e.target.value)}
                />
                <Switch
                  containerClassName={styles['chart-switch']}
                  options={[
                    { value: 'amount', label: 'Сумма, ₽' },
                    { value: 'count', label: 'Количество, шт.' },
                  ]}
                  value={dataType}
                  onChange={(e) => setDataType(e.target.value)}
                />
              </div>
            }
            labels={Object.keys(chartData)}
            actualValues={Object.values(chartData).map((value) =>
              Math.round(value[dataType].target)
            )}
            oldValues={Object.values(chartData).map((value) =>
              Math.round(value[dataType].previous)
            )}
            actualLabel={getActualLabel(period)}
            oldLabel={getOldLabel(period)}
            barBorder={3}
            rightPadding={getRightPadding(period)}
          />
        ) : undefined}
      </div>
      <div className={styles['table-and-pies-container']}>
        <RecordTable
          title="Детализация по артикулам"
          containerClassName={styles.table}
          columns={[
            { name: 'media_url', title: '', type: 'image', canSort: false },
            {
              name: 'vendor_code',
              title: 'Артикул продавца',
              customStyle: styles['big-column'],
            },
            { name: 'order_count', title: 'Заказали, шт.', type: 'number', customStyle: styles['number-column'] },
            { name: 'order_amount', title: 'Заказали, ₽', type: 'number', customStyle: styles['number-column'] },
            { name: 'sale_count', title: 'Выкупили, шт.', type: 'number', customStyle: styles['number-column'] },
            { name: 'sale_amount', title: 'Выкупили, ₽', type: 'number', customStyle: styles['number-column'] },
            { name: 'return_count', title: 'Вернули, шт.', type: 'number', customStyle: styles['number-column'] },
            { name: 'return_amount', title: 'Вернули, ₽', type: 'number', customStyle: styles['number-column'] },
          ]}
          filters={[
            { fieldName: 'seller_id', value: sellerId },
            { fieldName: 'goods_variant_id', value: goodsVariantId },
            { fieldName: 'period', value: period || 'today' },
          ]}
          resourcePath="/statistic/current/goods-variants"
          defaultSorting={[{ id: 'order_count', desc: true }]}
        />
      </div>
    </AppContainer>
  );
};

export default Dashboard;
